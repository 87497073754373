import React, { memo, useState, useEffect } from "react";
import { CssBaseline, useMediaQuery } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { onAuthStateChanged } from "firebase/auth";
import { ThemeProvider } from "@mui/material";
import { AppContext } from "./8_contexts/contextLib.js";
import IdleTimer from "./3_authentication/IdleTimer.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./7_theme/App.css";
import husibuechTheme from "./7_theme/Theme.js";
import MenuTopBar from "./5_pages/Navigation/1MenuTopBar/MenuTopBar.js";
import AppRoutes from "./5_pages/Navigation/AppRoutes.js";
import authService from "./6_services/auth.service.js";
import husibuechThemeDark from "./7_theme/ThemeDark.js";
import { auth } from "./3_authentication/firebase.js";
import { updateUserOnLogout } from "./4_generalHelpers/_AppHelpers/updateUserOnLogout.js";


function App() {
  /**
   * app navigation - settings
   */
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [appInDarkMode, setAppInDarkMode] = useState(true);
  const [currentPage, setCurrentPage] = useState("dashboard");
  const theme = appInDarkMode ? husibuechThemeDark : husibuechTheme;


  /**
   * Lock processes
   */
  const [loading, setLoading] = useState(false);
  const [verificationCodeStore, setVerificationCodeStore] = useState("");

  /**
   * orientation
   */
  const [currentUser, setCurrentUser] = useState(undefined);
  const [referenceDate, setReferenceDate] = useState(undefined)

  /**
   * For Verification
   */
  const [currentVerificationUser, setCurrentVerificationUser] =
    useState(undefined);
  const [verificationMessage, setVerificationMessage] = useState(false);
  const [verificationUserName, setVerificationUserName] = useState("");
  const [verificationUserPassword, setVerificationUserPassword] = useState("");

  /**
   * for authentication:
   * once authenticated, this enables page views for the authenticated user
   */
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  //screenSize
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("ss"));

  //husibuech App
  const [husibuechLoading, setHusibuechLoading] = useState(false)


  /*
   * Dark Mode Theme Setting
   */
  useEffect(() => {
    setAppInDarkMode(true);
  }, [prefersDarkMode]);

  /**
   * During Verificiation
   */
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentVerificationUser(user);
    });
  }, []);

  /**
   * User from local storage (if given)
   */
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        // Call authService.getCurrentUser() asynchronously
        const currentUserFromStorage = await authService.getCurrentUser();

        if (!loading && currentUserFromStorage) {
          await setCurrentUser(currentUserFromStorage);
          // console.log("loading starts from storage")
        }
      } catch (error) {
        // Handle errors if necessary
        console.error("Error fetching user:", error);
        await setLoading(false);
      }
    };

    fetchCurrentUser();
  }, [loading]);

  /*
   * after authentication process:
   */
  useEffect(() => {
    const acceptAuthentication = async () => {
      await setReferenceDate(moment(new Date()).format("YYYY-MM-DD"));
      setLoading(false)
      //start limeout process:
      const timer = new IdleTimer({
        timeout: 300, // Expire after 300 seconds
        onTimeout: async () => {
          await logout("timeout");
        },
        onExpired: async () => {
          await logout("expired");
        },
      });

      return () => {
        timer.cleanUp();
      };
    };

    if (isAuthenticated) {
      acceptAuthentication();
    }
  }, [isAuthenticated]);

  const logout = async (trigger) => {
    setLoggingOut(true);
    if (trigger !== "expired" && trigger !== "timer") {
      // console.log(localStorage.getItem("_expiredTime")>0)
      if (localStorage.getItem("_expiredTime") > 0 && currentUser)
        await updateUserOnLogout(dayjs(), currentUser);
    } else {
      console.log("trigger:", trigger);
    }
    setTimeout(async () => {
      setLoggingOut(true); //triggers Clearing of App Contexts
      setCurrentUser(undefined); //updateUser
      await authService.clearStorage();
      // Clean up other relevant data if needed
      setTimeout(async () => {
        setLoggingOut(false);
      }, 300);
    }, 1000);
  };

  //load User Husibuech information
  useEffect(() => {
    // console.log("we trigger this...5", currentUser? true :false)
    setIsAuthenticated(currentUser ? true : false);
  }, [currentUser]);


  /**
   * Overwrites the scrollbar
   */
  useEffect(() => {
    // Add scrollbar customization styles to the body or html element
    const style = document.createElement("style");
    style.innerHTML = `
      body::-webkit-scrollbar {
        width: 0px;
      }

      body::-webkit-scrollbar-thumb {
        background-color: gray;
        border-radius: 6px;
      }

      body::-webkit-scrollbar-track {
        background-color: transparent;
      }
    `;
    document.head.appendChild(style);

    // Clean up the style element when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div>
      {/* <div className="container mt-3"> */}
      <AppContext.Provider
        value={{
          isSmallScreen,
          isMobile,
          isAuthenticated,
          currentUser,
          referenceDate,
          setCurrentUser,
          currentPage,
          setCurrentPage,
          loggingOut,
          setLoggingOut,
          appInDarkMode,
          setAppInDarkMode,
          currentVerificationUser,
          setCurrentVerificationUser,
          verificationMessage,
          setVerificationMessage,
          verificationUserName,
          setVerificationUserName,
          verificationUserPassword,
          setVerificationUserPassword,
          verificationCodeStore,
          setVerificationCodeStore,
          loading,
          setLoading,
          logout,
          husibuechLoading, 
          setHusibuechLoading
        }}
      >
        {/* THEMED APP */}
        <ThemeProvider
          theme={appInDarkMode ? husibuechThemeDark : husibuechTheme}
        >
          <CssBaseline enableColorScheme />

          <MenuTopBar />
          <AppRoutes />
          {/* <MenuBottomBar /> */}
        </ThemeProvider>
      </AppContext.Provider>
    </div>
  );
}

export default memo(App);
