import dataService from "../../../HusibuechHelpers/2_Services/data.service";
import { filterUniqueAssignments } from "./4_Functions/filterUniqueAssignments";

export const calculateTopSix = async (
  schoolYearData,
  isALeader,
  showExams,
  showHomeworks,
  statusSelection,
  prioritySelection,
  setTopSix,
  selectedCourse
) => {
  // Destructure schoolYearData to get the required properties
  const { classAssignments, userAssignments } = schoolYearData;
  let courseClassAssignments
  if(isALeader){
    courseClassAssignments = await filterUniqueAssignments(classAssignments, "assignment_id")
    courseClassAssignments = courseClassAssignments.filter((assignment) => assignment.core_course_id === selectedCourse.core_course_id)
    // console.log(courseClassAssignments)

  }
    const top = await dataService.getMostRecentAssignments(
    isALeader
      ? courseClassAssignments
      : userAssignments,
    showExams,
    showHomeworks,
    statusSelection,
    prioritySelection
  );
  // console.log("top, ", top)

  await setTopSix(top);
};
