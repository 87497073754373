import React, { useState, useEffect, memo } from "react";
import dayjs from "dayjs";
import 'dayjs/locale/de';
import { DatePicker } from "@mui/x-date-pickers";
import { styled } from "@mui/styles";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { useAppContext } from "../../../../8_contexts/contextLib";

dayjs.locale("de");

const CustomPicker = styled(DatePicker)(({ canEdit, isDuringHoliday, theme, appindarkmode }) => ({
  "& .MuiInputLabel-root": {
    fontSize: "0.8rem",
    marginLeft: "-3px",
    width: "auto",
    whiteSpace: "nowrap",
    overflow: "visible",
    color: !isDuringHoliday ? theme.palette.icon.primary : theme.palette.levels.red,
    "&:disabled": {
      color: theme.palette.icon.primary, // Change the color of the value when disabled
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "0.9rem",
    marginLeft: canEdit ? "-0.5em" : "0em",
    marginRight: canEdit ? "-1em" : "-2.2em",
    borderRadius: canEdit ? "" : "6px",
    paddingRight: "-10px",
    color: appindarkmode === "true" ? theme.palette.levels.white : theme.palette.levels.dark,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.background.paper,
      WebkitTextFillColor: appindarkmode === "true" ? theme.palette.levels.white : theme.palette.levels.dark,
    },
  },

  "& .MuiSvgIcon-root": {
    color: canEdit ? theme.palette.primary.dark : "transparent",
    marginLeft: canEdit ? "-1em" : "-0.5em",
    marginRight: canEdit ? "" : "-0.3em"
  },
}));


function MuiPicker({ changeDate, chosenDate, restricted, boxType, special, extraDate }) {
  const { holidays, referenceDate, appInDarkMode } = useAppContext();
  const [selectedDate, setSelectedDate] = useState(chosenDate ? dayjs(chosenDate) : dayjs());
  const [holyDays, setHolyDays] = useState([]);
  // console.log("date,", selectedDate)
  // console.log("date was changed", special, dayjs(selectedDate).format("DD-MM-YYYY"))


  // console.log("dark:", appInDarkMode.toString()==="true")
  useEffect(() => {
    if (holidays !== undefined) {
      var newHolidayDays = [];

      for (var i = 0; i < holidays.length; i++) {
        var now = dayjs(holidays[i].start_date).format("YYYY-MM-DD");
        var end = dayjs(holidays[i].end_date).format("YYYY-MM-DD");

        while (dayjs(now).isBefore(end) || dayjs(now).isSame(end)) {
          newHolidayDays.push(now);
          now = dayjs(now).add(1, "days").format("YYYY-MM-DD");
        }
      }
      setHolyDays(newHolidayDays);
    }
  }, [holidays]);


  useEffect(() => {
    // console.log("reference changed")
    if (boxType === "add" || boxType ==="repetition") {
      // console.log("we go here")
      setSelectedDate(dayjs(referenceDate))
      changeDate(referenceDate)
    }
  }, [referenceDate])

    // Update selectedDate when chosenDate prop changes
    useEffect(() => {
      setSelectedDate(chosenDate ? dayjs(chosenDate) : dayjs());
    }, [chosenDate]);


  const shouldDisableDate = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    if (special) {
      return dayjs(date).isBefore(dayjs(extraDate))
    } else {
      return holyDays.includes(formattedDate);
    }
  };

  const onChange = (date) => {
    // console.log("date was changed", special)
    setSelectedDate(date);
    changeDate(date);
  };


  const isDuringHoliday = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    return holyDays.includes(formattedDate);
  };


  return (
    <CustomPicker
      label={isDuringHoliday(selectedDate) ? "Ferien" : "Datum"}
      disabled={restricted}
      canEdit={!restricted}
      isDuringHoliday={isDuringHoliday(selectedDate)}
      format="dd, DD.MM"
      value={selectedDate}
      onChange={(date) => onChange(date)}
      shouldDisableDate={shouldDisableDate}
      components={{ OpenPickerIcon: AlarmOnIcon }}
      startOfWeek="monday"
      appindarkmode={appInDarkMode.toString()}
    />
  );
}

export default memo(MuiPicker);
