import React, { memo, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { useHusibuechContext } from "../../../../../8_contexts/husibuechContext";
import BoxHeader from "../../../../../4_generalHelpers/1_Boxes/BoxHeader";
import { createTeacherName } from "../../../../../4_generalHelpers/4_Text/nameHelpers";
import MiddleBox from "../../1_Boxes/MiddleBox";
import { getRelevantAssignmentsObject } from "../_WorkloadChartHelpers/getRelevantAssignmentsObject";
import YearViewComponent from "./YearViewComponent";

function YearView({workloadFlip, setWorkloadFlip}) {
  const {
    isMobile,
    isAStudent,
    isALeader,
    selectedCourse,
    showCourseAssignmentOnly,
    schoolYearData,
    showExams,
    showHomeworks,
    showPersonals,
    statusSelection,
    prioritySelection,
    } = useHusibuechContext();
  const [mondays, setMondays] = useState([])
  const [weeks, setWeeks] = useState([])
  const [userWorkload, setUserWorkload] = useState([])
  const [classWorkload, setClassWorkload] = useState([])
  const containerRef = useRef(null);
  const [height, setHeight] = useState(null)

  useEffect(() => {
    const adjustFontSize = async () => {
      const containerHeight = containerRef.current.clientHeight; // Use clientHeight
      await setHeight(containerHeight);
      // console.log(containerHeight);
    };

    // Call the adjustFontSize function initially and whenever the window is resized
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, []);

  useEffect(() => {
    if (schoolYearData) {
      let dates = schoolYearData?.mondaysFirstSemester.concat(schoolYearData?.mondaysSecondSemester)
      setMondays(dates)
    }
  }, [schoolYearData])

  useEffect(() => {
    if (mondays.length > 0) {
      let dates = []
      mondays.map(date => {
        const week = dayjs(date).week();
        dates.push(week)
      });
      // console.log(dates);
      setWeeks(dates)
    }
  }, [mondays]);

  useEffect(() => {
    const calculateData = async () => {
      let currentUserWorkload = [];
      for (const monday of mondays) {
        let relevantAssignments = [];
        let currentWorkload = 0;

        let newRelevants = await getRelevantAssignmentsObject(
          schoolYearData,
          showExams,
          showHomeworks,
          showPersonals,
          statusSelection,
          prioritySelection,
          monday,
          true
        );

        relevantAssignments = newRelevants.thisWeek.concat(
          newRelevants.additional.daysToPrepare,
          newRelevants.additional.extraDates
        );

        if (showCourseAssignmentOnly && selectedCourse) {
          relevantAssignments = relevantAssignments.filter((assignment) =>
            assignment.course_id === selectedCourse.id
          )
        }
        relevantAssignments.forEach((assignment) => {
          currentWorkload += assignment.my_minutes;
        });

        currentUserWorkload.push(currentWorkload);
      }

      setUserWorkload(currentUserWorkload);
    };

    if (mondays.length > 0) {
      calculateData();
    }
  }, [mondays, showCourseAssignmentOnly, selectedCourse]);

  useEffect(() => {
    const calculateDataClass = async () => {
      let currentClassWorkload = [];

      for (const monday of mondays) {
        let relevantAssignments = [];
        let currentWorkload = 0;

        let newRelevants = await getRelevantAssignmentsObject(
          schoolYearData,
          showExams,
          showHomeworks,
          showPersonals,
          statusSelection,
          prioritySelection,
          monday,
          isALeader ? false : true //for class
        );

        relevantAssignments = newRelevants.thisWeek.concat(
          newRelevants.additional.daysToPrepare,
          newRelevants.additional.extraDates
        );
        if (isALeader && selectedCourse?.id) {
          relevantAssignments = relevantAssignments.filter((assignment) => assignment.core_course_id === selectedCourse.core_course_id && assignment.type !== "personal")
          if (showCourseAssignmentOnly) {
            relevantAssignments = relevantAssignments.filter((assignment) =>
              assignment.course_id === selectedCourse.id
            )
          }
        } else {
          relevantAssignments = relevantAssignments.filter((assignment) => assignment.type !== "personal")

        }
        relevantAssignments.forEach((assignment) => {
          currentWorkload += assignment.suggested_minutes; //for class
        });

        currentClassWorkload.push(currentWorkload);
      }

      setClassWorkload(currentClassWorkload);
    };

    if (mondays.length > 0) {
      calculateDataClass();
    }
  }, [mondays, selectedCourse, showCourseAssignmentOnly]);

  return (
    <>
      <BoxHeader
        title={
          isAStudent
            ? "Deine Jahresübersicht"
            : selectedCourse && showCourseAssignmentOnly
              ? `Jahresübersicht im Kurs ${selectedCourse?.name ? selectedCourse.name : ""}`
              : selectedCourse
                ? `Jahresübersicht der Klasse ${selectedCourse?.name ? createTeacherName(selectedCourse.name) : ""}`
                : `Jahresübersicht`
        }
        options={isMobile && isAStudent? []:isAStudent?["workloadFlip"]:isMobile && isALeader? ["courseView"] : ["courseView", "workloadFlip"]}
        workloadFlip={workloadFlip}
        setWorkloadFlip={setWorkloadFlip}
      />

      <MiddleBox>
        <div 
          ref={containerRef}
          style={{ height: "100%", overflow: "hidden" }}>
          <YearViewComponent height={height} mondays={mondays} weeks={weeks} userWorkload={userWorkload} classWorkload={classWorkload}/>
        </div>
      </MiddleBox>
    </>
  );
}
export default memo(YearView);
