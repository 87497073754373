import React, { memo } from "react";
import { useAppContext } from "../../../../../8_contexts/contextLib";
import { CustomTextField } from "./CustomTextField";
import { MenuItem } from "@mui/material";
import { useHusibuechContext } from "../../../../../8_contexts/husibuechContext";
import { useTheme } from "@mui/styles";

function ExamTypeBoxComponent({ restricted, examTypeId, change }) {
  const { appInDarkMode } = useAppContext();
  const {husibuechExamTypes} = useHusibuechContext()
  const theme = useTheme()
  return (
    <>
      <CustomTextField
        // labelId="type-lable"
        id="type"
        select
        disabled={restricted}
        onChange={change}
        value={examTypeId}
        label="Prüfungstyp"
        variant="outlined"
        InputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        paper={theme.palette.background.paper}
        white={theme.palette.levels.white}
        dark={theme.palette.levels.dark}
      >
        {husibuechExamTypes.map((option, i) => (
          <MenuItem key={i} value={option.id} style={{ fontSize: "0.8rem" }}>
            {option.name}
          </MenuItem>
        ))}
      </CustomTextField>
    </>
  );
}

export default memo(ExamTypeBoxComponent);
