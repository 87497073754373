const { getUserData } = require('./getUserData.js');

export const loadAdminData = async (
  currentUser,
  setHusibuechTeachers,
  setHusibuechUsers,
  setHusibuechRoles,
  setHusibuechSubjects,
  sethusibuechSemesters,
  sethusibuechHolidays
) => {
  if (currentUser.roles.includes("Admin")) {
    const teachers = await getUserData("teachers");
    // console.log("teach", teachers)
    setHusibuechTeachers(teachers);

    let users = await getUserData("users");
    users.forEach((user) => {
      user.roles = JSON.parse(user.roles.replace(/\\\"/g, '"'));
      user.courses = JSON.parse(user.courses.replace(/\\\"/g, '"'));
    });
    setHusibuechUsers(users);

    const roles = await getUserData("role");
    setHusibuechRoles(roles);

    const appSubjects = await getUserData("subject");
    setHusibuechSubjects(appSubjects);

    const appSemesters = await getUserData("semester");
    sethusibuechSemesters(appSemesters);

    const appHolidays = await getUserData("holiday");
    sethusibuechHolidays(appHolidays);
  }
};
