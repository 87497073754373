import { styled, Paper } from "@mui/material";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "#1A2027"
      : theme.palette.background.paper,
  ...theme.typography.body2,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
