import assignmentService from "../2_Services/assignment.service";

export const createAssignment = async (currentUser, assignmentProperties) => {
  try {
    const assignmentAttributes = {
      creator_id: currentUser.id,
      visible: true,
    };

    // Iterate over assignmentProperties and add each property to assignmentAttributes
    assignmentProperties.forEach(({ key, value }) => {
      assignmentAttributes[key] = value;
    });
    console.log(assignmentAttributes)
    const response = await assignmentService.createNewAssignment(assignmentAttributes);
    return response.data.assignment;
    return null
  } catch (error) {
    throw error; // Rethrow the error for handling in the calling component
  }
};
