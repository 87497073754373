import { loadAdminData } from "./loadAdminData";
import { loadStudentData } from "./loadDataForStudent.js";
import { loadTeacherData } from "./loadDataForTeacher";
const { getUserData } = require('./getUserData.js');

export const loadDataForUser = async (
  currentUser,
  setNumberOfFinishedExamStatus,
  setNumberOfFinishedHomeworkStatus,
  setShowActivationForm,
  setHolidays,
  setMyCoreCourses,
  setHusibuechTeachers,
  setHusibuechUsers,
  setHusibuechRoles,
  setHusibuechSubjects,
  sethusibuechSemesters,
  sethusibuechHolidays,
  setHusibuechCourses,
  setHusibuechCoreCourses,
  setUserSubjects,
  setUserCourses,
  setHusibuechExamTypes
) => {
  await setNumberOfFinishedExamStatus(currentUser.examsDoneOnLogout);
  await setNumberOfFinishedHomeworkStatus(currentUser.homeworksDoneOnLogout);
  const systemCourses = await getUserData('systemcourses');
  await setHusibuechCourses(systemCourses)
  const systemCoreCourses = await getUserData('core_course');
  await setHusibuechCoreCourses(systemCoreCourses)

  const holidays = await getUserData('holiday');
  await setHolidays(holidays);

  const husibuechExamTypes = await getUserData('exam_type');
  await setHusibuechExamTypes(husibuechExamTypes);

  await loadStudentData(currentUser, setMyCoreCourses)
  await loadTeacherData(currentUser, setMyCoreCourses);
  await loadAdminData(
    currentUser,
    setHusibuechTeachers,
    setHusibuechUsers,
    setHusibuechRoles,
    setHusibuechSubjects,
    sethusibuechSemesters,
    sethusibuechHolidays
  );

  const subjects = await getUserData('subjects', currentUser);
  await setUserSubjects(subjects);

  const courses = await getUserData('courses', currentUser);
  await setUserCourses(courses);

  if (!currentUser.isActive || 
    currentUser.loginCount === 0 || 
    currentUser.loginCount === 1){
    await setShowActivationForm(true);
  }

};
