import React, { memo } from "react";
import { Grid2 } from "@mui/material";
import { useAppContext } from "../../../../8_contexts/contextLib";
import CountingFactorBoxComponent from "./1_CardElements/CountingFactorBoxComponent";
import ExamTypeBoxComponent from "./1_CardElements/ExamTypeBoxComponent";
import CourseBoxComponent from "./1_CardElements/CourseBoxComponent";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";
// import ErrorBox from "../CardElements/ErrorBox";

function LineTwo({ type, selectedAssignmentCourse, onChangeAssignmentCourse, countingFactor, onChangeCountingFactor, countingFactorErr, assignmentExamTypeId, onChangeExamType }) {
    const {userCourses} = useHusibuechContext()
    return (

        <Grid2
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            {/* Course */}
            <Grid2 xs={4}>
                <CourseBoxComponent
                    restricted={false}
                    selectedAssignmentCourse={selectedAssignmentCourse}
                    userCourses={userCourses}
                    change={onChangeAssignmentCourse}
                />
            </Grid2>
            {/* Prüfungsdetails Container */}
            {type === "exam" && (
                <Grid2 xs={5}>
                    <Grid2 container justifyContent="flex-start" spacing={1}>
                        {/* Gewicht */}

                        <Grid2 xs={5}>
                            <CountingFactorBoxComponent
                                restricted={false}
                                countingFactor={countingFactor}
                                change={onChangeCountingFactor}
                                countingFactorErr={countingFactorErr}
                            />
                        </Grid2>
                        {/* Prüfungstyp */}
                        <Grid2 xs={5}>
                            <ExamTypeBoxComponent
                                restricted={false}
                                examTypeId={assignmentExamTypeId}
                                change={onChangeExamType}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
            )}
        </Grid2>
    );
}

export default memo(LineTwo);
