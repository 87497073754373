import React from "react";
import { Box } from "@mui/material";

const MiddleBox = ({ children }) => (
  <Box
    marginTop={2}
    sx={{
      height: { xs: 300, sm: 335, xl: 332 },
      position: "relative",
      overflowX: "auto", // Hide x-axis scrollbar
      "&::-webkit-scrollbar": {
        width: "0px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "your-desired-color", // Set your desired color
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent", // or any background color
      },
    }}
  >
    {children}
  </Box>
);

export default MiddleBox;
