import { sortTasksByDueDateAndPriority } from "./1_DeadlineHelpers/sortTasksByDueDateAndPriority";

export const calculateDeadlineAssignments = async (relevantUserAssignments, setPersonalDeadlineAssignments, selectedCourse) => {
    // console.log("...calculate, ", relevantUserAssignments?.thisWeek?.length)

    if (relevantUserAssignments?.thisWeek?.length > 0) {
        let assignments = relevantUserAssignments.thisWeek
        if(selectedCourse){
            assignments = assignments.filter((assignments)=> assignments.core_course_id === selectedCourse.core_course_id)
        }
        const sortedAssignments = await sortTasksByDueDateAndPriority(assignments);
        await setPersonalDeadlineAssignments(sortedAssignments);
    } else {
        await setPersonalDeadlineAssignments([]);
    }
};
