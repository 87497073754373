
const { default: workloadService } = require("../../../HusibuechHelpers/2_Services/workload.service");
const { calculateWorkloadArray } = require("./calculateWorkloadArray");

async function calculateFitsInWeek(
  referenceDate,
  relevantPersonalAssignmentsByDay,
  relevantExtraDateAssignmentsByDay,
  key,
  personalWorkWeekData
) {
  const tempArray = await calculateWorkloadArray(
    referenceDate,
    relevantPersonalAssignmentsByDay,
    key,
    relevantExtraDateAssignmentsByDay
  );

  return await workloadService.fitsInWeek(personalWorkWeekData, tempArray);
}
exports.calculateFitsInWeek = calculateFitsInWeek;
