import React, { memo } from "react";

function MessageBox({ message, color }) {
  return (
    <>
      <div
        className={
          color === "success" ? "alert alert-success" : "alert alert-danger"
        }
        role="alert"
        style={{
          paddingTop: "-0.8em",
          marginRight: "1.2em",
          height: "30px"
        }}
      >
        <div
          style={{
            marginTop: "-0.6em",
            marginBottom: "-0.45em",
            marginLeft: "-0.3em",
            paddingBottom: "-1em",
          }}
        >
          {message}
        </div>
      </div>
    </>
  );
}

export default memo(MessageBox);
