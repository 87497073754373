import React, { memo } from "react";

import { useAppContext } from "../../../../8_contexts/contextLib";
import DatumBoxComponent from "./1_CardElements/DatumBoxComponent";
import { Grid2 } from "@mui/material";
import AufwandBoxComponent from "./1_CardElements/AufwandBoxComponent";
import DaysToPrepareBoxComponent from "./1_CardElements/DaysToPrepareBoxComponent";

function LineThree({ type, date, onChangeDueDate, my_minutes, onChangeWorkloadMinutesTotal, workloadErr, my_days, onChangeAvailablePrepDays, restricted, boxType }) {
    const { referenceDate } = useAppContext()
    return (
        <Grid2 container justifyContent="flex-start" direction="row" spacing={1}>
            {/* Date */}
            <Grid2 xs={4}>
                <DatumBoxComponent
                    restricted={restricted}
                    dueDate={date ? date : referenceDate}
                    change={onChangeDueDate}
                    boxType={boxType}
                />
            </Grid2>
            {/* Aufwand */}
            <Grid2 xs={4}>
                <AufwandBoxComponent
                    restricted={restricted}
                    my_minutes={my_minutes}
                    suggested_minutes={my_minutes}
                    type={type}
                    workloadErr={workloadErr}
                    personalView={false}
                    change={onChangeWorkloadMinutesTotal}
                />
            </Grid2>

            {/* Vorbereitung*/}
            <Grid2 xs={3}>
                <DaysToPrepareBoxComponent
                    restricted={restricted}
                    my_days={my_days}
                    suggested_days={my_days}
                    type={type}
                    personalView={false}
                    change={onChangeAvailablePrepDays}
                />
                {/* <ErrorBox error={availablePrepDaysErr} />*/}
            </Grid2>
        </Grid2>

    );
}

export default memo(LineThree);
