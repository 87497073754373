import { memo, useState } from "react";

import { useAppContext } from "../../../../8_contexts/contextLib.js";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext.js";
import DeadlineAssignmentsTFlip from "./DeadlineAssignmentsTFlip.js";
import BoxHeader from "../../../../4_generalHelpers/1_Boxes/BoxHeader.js";
import DeadlineAssignmentsList from "./DeadlineAssignmentsList.js";
import MiddleBox from "../1_Boxes/MiddleBox.js";
import { getBoxHeaderOptions } from "../../../../4_generalHelpers/1_Boxes/getBoxHeaderOptions.js";
import { getBoxHeaderTitle } from "../../../../4_generalHelpers/1_Boxes/getBoxHeaderTitle.js";

function DeadlineAssignments({ type, flipAssignment, setFlipAssignment, single }) {
  const {currentUser} = useAppContext
  const {
    isAStudent,
    isATeacher,
    isAnAdmin,
    isALeader,
    selectedCourse,
    showCourseAssignmentOnly,
  } = useHusibuechContext();
  
  const [teacherFlip, setTeacherFlip] = useState(true);


  // console.log("type, ", type)
  const shouldRenderPersonalList =
    (isALeader && type === "personal") ||
    (isAStudent && type === "studentView") ||
    (currentUser && type === "personalEmpty");
  // console.log("pers", shouldRenderPersonalList)

  const shouldRenderCoreCourseList = isALeader && type === "coreCourse";

  const boxHeaderTitle = getBoxHeaderTitle(
    isAStudent,
    isATeacher,
    isAnAdmin,
    selectedCourse,
    type,
    showCourseAssignmentOnly
  );
  const boxHeaderOptions = getBoxHeaderOptions(type, single);

  return (
    shouldRenderCoreCourseList && isALeader ?
      <DeadlineAssignmentsTFlip single={single} /> :
      <>
        <BoxHeader
          title={boxHeaderTitle}
          options={boxHeaderOptions}
          flipAssignment={flipAssignment}
          setFlipAssignment={setFlipAssignment}
          flipTeacherAssignment={teacherFlip}
          setFlipTeacherAssignment={setTeacherFlip} />
        <MiddleBox>
          <DeadlineAssignmentsList
            type={
              shouldRenderPersonalList
                ? "personal"
                : "additional"
            }
          />
        </MiddleBox>
      </>
  );
}

export default memo(DeadlineAssignments);
