import { Grid2 } from "@mui/material";
import React, { memo, useState } from "react";
import { useHusibuechContext } from "../../8_contexts/husibuechContext";
import { useAppContext } from "../../8_contexts/contextLib";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MainContentGrid from "./HusibuechComponents/1_Boxes/MainContentGrid";
import { Item } from "./HusibuechComponents/1_Boxes/Item";
import AssignmentAddBox from "./HusibuechComponents/2_AssignmentAdd/AssignmentAddBox";
import PersonalDeadlineBox from "./HusibuechComponents/4_DeadlineBox/PersonalDeadlineBox";
import RecentlyAdded from "./HusibuechComponents/5_RecentlyAdded/RecentlyAdded";
import WorkloadFlip from "./HusibuechComponents/6_WorkloadChart/WorkloadFlip";
import DeadlineAssignments from "./HusibuechComponents/4_DeadlineBox/DeadlineAssignments";

const defaultItemsOrder = ["core-deadlines", "test", "personal-deadlines", "doughnut-box", "workload-box", "recently-added", "todo-list"];
const dashboardOrder = ["core-deadlines", "personal-deadlines", "workload-box", "recently-added",]

function HusibuechContent() {
  const { currentUser } = useAppContext()
  const { isAStudent, orderIsFixed } = useHusibuechContext()

  // State to store the order of items
  const [itemsOrder, setItemsOrder] = useState(() => {
    try {
      const storedOrder = currentUser?.dashboardOrder;
      // return getDefaultItemsOrder();
      if (storedOrder && typeof storedOrder === "string") {
        return JSON.parse(storedOrder);
      } else if (Array.isArray(storedOrder)) {
        return storedOrder;
      } else {
        return defaultItemsOrder; // Return the default order
      }
    } catch (error) {
      console.error("Error parsing stored order:", error);
      return defaultItemsOrder;
    }
  });



  // Callback function for reordering items
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return; // Dragged outside the list
    }

    const newItemsOrder = Array.from(itemsOrder);
    newItemsOrder.splice(result.source.index, 1);
    newItemsOrder.splice(result.destination.index, 0, result.draggableId);
    await setItemsOrder(newItemsOrder);
  };

  const components = {
    "test": () => <AssignmentAddBox single={true} />,
    // "todo-list": () => (<AssignmentAddBox />),
    "personal-deadlines": () => <PersonalDeadlineBox single={false} />,
    // "doughnut-box": () => (isAStudent ? <AssignmentAddBox /> : null),
    "workload-box": () => <WorkloadFlip />,
    "recently-added": () => (
      <RecentlyAdded type={isAStudent ? "student" : "teacher"} />
    ),
    "core-deadlines": () => (
      <DeadlineAssignments
        type={isAStudent ? "studentView" : "coreCourse"}
        single={false}
      />
    ),
    // "todo-list": () => <AssignmentAddBox/>,
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",         // Use flexbox for layout
        flexDirection: "column", // Stack items vertically (from top to bottom)
        alignItems: "center",    // Center the items horizontally
        justifyContent: "center", // Center the items vertically
        minHeight: "30rem",         // Ensure the container has a fixed height
        gap: "3rem",             // Add gap between the items
        // backgroundColor: "green"
      }}
    >

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="dashboard-content" direction="vertical" isDropDisabled={!!false}>
          {(provided) => (
            <MainContentGrid ref={provided.innerRef} {...provided.droppableProps}>
              {itemsOrder?.map((itemId, index) => {
                // if (currentUser?.dashboardOrder?.includes(itemId)) {
                if (dashboardOrder?.includes(itemId)) {
                  return (
                    <Draggable
                      key={itemId}
                      draggableId={itemId}
                      index={index}
                      isCombineEnabled={true}
                      isDragDisabled={orderIsFixed}
                      isDropDisabled={orderIsFixed}
                    >

                      {(provided) => (
                        <div
                          // xs={isAStudent && (itemId === "doughnut-box" || itemId === "recently-added") ? 12 : 12}
                          // sm={isAStudent && (itemId === "doughnut-box" || itemId === "recently-added") ? 6 : 12}
                          // md={itemId === "todo-list" ? 12 : isAStudent && (itemId === "doughnut-box" || itemId === "recently-added") ? 3 : 6}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Item
                            elevation={5}
                            sx={{ padding: { xs: 1, ss: 2, lg: 3 } }}
                          >
                            {components[itemId]?.()}
                          </Item>
                        </div>
                      )}
                    </Draggable>
                  );
                }
              })}
              {provided.placeholder}
            </MainContentGrid>
          )}
        </Droppable>
      </DragDropContext>

    </div >
  );
}

export default memo(HusibuechContent);

