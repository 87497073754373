const { styled, TextField } = require("@mui/material");

const CustomTextField = styled(TextField)(({appindarkmode, paper, white, dark }) => ({
  "& label": {
    // right: "unset",
    // right: "-5rem",
    // transformOrigin: "right",
    fontSize: "0.8rem",
    marginLeft: "-3px", // Adjust the value as needed
    width: "auto", // Set a specific width or use "auto" to adjust dynamically
    whiteSpace: "nowrap", // Prevent label text from wrapping
    overflow: "visible", // Hide the overflow
    // textOverflow: "ellipsis", // Display an ellipsis (...) for overflow
    // minWidth: "0", // Set a minimum width to handle very short texts

    // transform: "translate(2px, -12px) scale(0.7)", // Adjust the values as needed
    // width: "auto", // Set a specific width or use "auto" to adjust dynamically
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: paper
    // color: "green"
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: appindarkmode ==="true"?white:dark,
  },
  "&  .MuiInputLabel-root.Mui-disabled": {
    color: "#38679c",
  },
  ".MuiInput-root": {
    fontSize: "0.8rem",
  },
  // input: {
  //   "& input.Mui-disabled": {
  //     color: "red"
  //   }
  // }
}));

exports.CustomTextField = CustomTextField;
