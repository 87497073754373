import axios from "axios";
import authHeader from "../../../../6_services/auth-header";
import { SERVER_URL } from "../../../../2_deployment/constants";

class AppService {
  get(parameter) {
    try {
      const response =  axios.get(SERVER_URL + "protectedsystem/" + parameter, {
        headers: authHeader(),
      });
      return response
    } catch (e) {
      console.log("We had a problem with the database query: ", e);
      return [];
    }
  }

  // registerNewSubject(subjectFrontendDTO) {
  //   try {
  //     return axios.post(
  //       SERVER_URL + "create/subject",
  //       subjectFrontendDTO,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // registerNewCourse(courseFrontendDTO) {
  //   // console.log(courseFrontendDTO);
  //   try {
  //     return axios.post(
  //       SERVER_URL + "create/course",
  //       courseFrontendDTO,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // registerNewSemester(semesterFrontendDTO) {
  //   // console.log(courseFrontendDTO);
  //   try {
  //     return axios.post(
  //       SERVER_URL + "create/semester",
  //       semesterFrontendDTO,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // registerNewHoliday(holidayFrontendDTO) {
  //   // console.log(holidayFrontendDTO);
  //   try {
  //     return axios.post(
  //       SERVER_URL + "create/holiday",
  //       holidayFrontendDTO,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // registerNewExamType(examTypeFrontendDTO) {
  //   console.log(examTypeFrontendDTO);
  //   // console.log(courseFrontendDTO);
  //   try {
  //     return axios.post(
  //       SERVER_URL + "create/examtype",
  //       examTypeFrontendDTO,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // registerNewCoreCourse(coreCourseFrontendDTO) {
  //   // console.log(coreCourseFrontendDTO);
  //   try {
  //     return axios.post(
  //       SERVER_URL + "create/corecourse",
  //       coreCourseFrontendDTO,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // registerUserThroughAdmin(userFrontendDTO) {
  //   // console.log(userFrontendDTO);
  //   try {
  //     return axios.post(
  //       SERVER_URL + "create/user",
  //       userFrontendDTO,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // activateDatabaseUpdate(dateString) {
  //   if (dateString === undefined || dateString === null) {
  //     dateString = new Date();
  //   }
  //   try {
  //     return axios.get(SERVER_URL + "update?date=" + dateString, {
  //       headers: authHeader(),
  //     });
  //   } catch (e) {
  //     // console.log("We had a problem with the database update: ");
  //     return e;
  //   }
  // }
}

const appServiceInstance = new AppService();
export default appServiceInstance;
