import { memo } from "react";
import { Grid } from "@mui/material";
import ButtonController from "./ButtonController";

function ButtonGrid({ disabled, loading, submit, clear }) {
    return (
        <Grid item style={{ marginTop: "0.75em" }}>
            <div style={{ visibility: disabled ? "hidden" : "visible" }}>
                <ButtonController
                    localLoading={loading}
                    disabled={disabled}
                    save={submit}
                    clear={clear}
                    saveText={"speichern"}
                    clearText={"verwerfen"}
                />
            </div>
        </Grid>
    );
}
export default memo(ButtonGrid);
