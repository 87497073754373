import React, { memo } from "react";

import { Grid2, Typography } from "@mui/material";

function LineInfo() {
    return (
        <Grid2  xs={12} style={{ marginLeft: "0.25em" }}>
            <Grid2
                container
                justifyContent="flex-start"
                direction="row"
            // spacing={1}
            >
                <Typography variant="body2">
                    Du willst für deine Klasse Hausaufgaben und Prüfungen eintragen?
                    <br />
                    Kontaktiere husibuech.ch und werde Referenzschüler*in
                    (info@husibuech.ch)
                </Typography>
            </Grid2>
        </Grid2>
    );
}

export default memo(LineInfo);
