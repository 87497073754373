const { default: workloadService } = require("../../../HusibuechHelpers/2_Services/workload.service");
const { groupMixedAssignmentsByType } = require("./groupMixedAssignmentsByType");
const { separateMixedCourses } = require("./separateMixedCourses");

async function calculateWorkloadArray(
  referenceDate,
  assignmentsByDay,
  key,
  extraAssignmentsByDay
) {
  const tempArray = [0, 0, 0, 0, 0, 0, 0];

  let assignmentPayload;
  if (key === "personal") {
    assignmentsByDay.forEach((relevantDay, indexDay) => {
      relevantDay.forEach(async (assignment) => {
        assignmentPayload =
          await workloadService.getAssignmentPayloadBasedOnMinutesDone(
            assignment,
            "personal"
          );
        tempArray[indexDay] += assignmentPayload;
      });
    });
    if (extraAssignmentsByDay) {
      extraAssignmentsByDay.forEach((relevantDay, indexDay) => {
        relevantDay.forEach((assignment) => {
          const relevantDaysToPrepare =
            workloadService.calculateRelevantDaysToPrepare(referenceDate, assignment);
          // console.log("rel days, ", relevantDaysToPrepare)
          const assignmentPayload =
            workloadService.getExtraDateAssignmentPayload(
              assignment,
              relevantDaysToPrepare,
              "my_minutes"
            );
          tempArray[indexDay] += assignmentPayload;
        });
      });
    }
  } else if (key === "class") {
    assignmentsByDay.forEach(async (relevantDay, indexDay) => {
      //deal with the fact that some assignments are mixed type course assignments: EF, SF, etc.
      const { nonMixed, mixed } = await separateMixedCourses(relevantDay);
      //deal with nonMixed:
      nonMixed.forEach(async (assignment) => {
        assignmentPayload =
          workloadService.getAssignmentPayloadBasedOnMinutesDone(
            assignment,
            "class"
          );
        tempArray[indexDay] += assignmentPayload;
      });
      //deal with mixed:
      const groupedLists = await groupMixedAssignmentsByType(mixed);
      groupedLists.forEach(async (group) => {
        assignmentPayload =
          workloadService.getAssignmentPayloadBasedOnMinutesDone(
            group,
            "group"
          );
        tempArray[indexDay] += assignmentPayload;
      });
    });
  } else {
    // For other keys, do nothing (no payload addition)
  }

  return tempArray;
}
exports.calculateWorkloadArray = calculateWorkloadArray;
