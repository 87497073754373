import dataService from "../2_Services/data.service";

export const updateDataCheck = async (
  currentAssignments,
  newAssignments,
  selectedCourse,
  oldSelectedCourse,
  passKey,
  type
) => {
  return (
    currentAssignments === undefined ||
    selectedCourse?.id !== oldSelectedCourse?.id ||
    (await dataService.relevantDataHasReallyChanged(
      newAssignments,
      currentAssignments,
      type
    )) ||
    passKey // This is only for emergencies
  );
};
