import { memo, useEffect, useState } from "react";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
import HusibuechContent from "./HusibuechContent";
import { HusibuechContext } from "../../8_contexts/husibuechContext";
import LoadingDashboardContent from "../../4_generalHelpers/Loading/LoadingDashboardContent";
import { useAppContext } from "../../8_contexts/contextLib";
import { loadDataForUser } from "./HusibuechHelpers/1_Functions/loadDataForUser";
import userService from "./HusibuechHelpers/2_Services/user.service";
import moment from "moment";
import { updateDataCheck } from "./HusibuechHelpers/1_Functions/updateDataCheck";
import { getRelevantAssignmentsObject } from "./HusibuechComponents/6_WorkloadChart/_WorkloadChartHelpers/getRelevantAssignmentsObject";
import ActivationForm from "./HusibuechComponents/10_ActivationForm/ActivationForm";

function Husibuech() {

  const { loggingOut, currentUser, husibuechLoading, setHusibuechLoading, isAuthenticated } = useAppContext()
  const [referenceDate, setReferenceDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [isAStudent, setIsAStudent] = useState(false);
  const [isATeacher, setIsATeacher] = useState(false);
  const [isAnAdmin, setIsAnAdmin] = useState(false);
  const [isAReferenceStudent, setIsAReferenceStudent] = useState(false);
  const [isALeader, setIsALeader] = useState(false);
  const [orderIsFixed, setOrderIsFixed] = useState(true);
  const [numberOfFinishedExamStatus, setNumberOfFinishedExamStatus] = useState(undefined);
  const [numberOfFinishedHomeworkStatus, setNumberOfFinishedHomeworkStatus] = useState(undefined);
  const [showActivationForm, setShowActivationForm] = useState(false);  // Assuming it's a boolean
  const [holidays, setHolidays] = useState([]);  // Assuming it's an array
  const [myCoreCourses, setMyCoreCourses] = useState([]);  // Assuming it's an array
  const [mySelectedCoreCourse, setMySelectedCoreCourse] = useState([]);
  const [husibuechTeachers, setHusibuechTeachers] = useState([]);  // Assuming it's an array
  const [husibuechUsers, setHusibuechUsers] = useState([]);  // Assuming it's an array
  const [husibuechRoles, setHusibuechRoles] = useState([]);  // Assuming it's an array
  const [husibuechSubjects, setHusibuechSubjects] = useState([]);  // Assuming it's an array
  const [husibuechSemesters, setHusibuechSemesters] = useState([]);  // Assuming it's an array
  const [husibuechHolidays, setHusibuechHolidays] = useState([]);  // Assuming it's an array
  const [husibuechCourses, setHusibuechCourses] = useState([]);  // Assuming it's an array
  const [husibuechCoreCourses, setHusibuechCoreCourses] = useState([]);  // Assuming it's an array
  const [husibuechUserKurse, setHusibuechUserKurse] = useState(undefined);
  const [userSubjects, setUserSubjects] = useState([]);  // Assuming it's an array
  const [userCourses, setUserCourses] = useState(undefined);  // Assuming it's an array
  const [husibuechExamTypes, setHusibuechExamTypes] = useState([]);  // Assuming it's an array
  const [personalView, setPersonalView] = useState(false)
  const [currentSemesterStartDate, setCurrentSemesterStartDate] = useState("");
  const [currentSemesterEndDate, setCurrentSemesterEndDate] = useState("");
  const [currentSemesterName, setCurrentSemesterName] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(undefined)


  /**
   * app filters
   */
  const [showExams, setShowExams] = useState(true);
  const [showHomeworks, setShowHomeworks] = useState(true);
  const [showPersonals, setShowPersonals] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [showCourseAssignmentOnly, setShowCourseAssignmentOnly] = useState(false);
  const [oldSelectedCourse, setOldSelectedCourse] = useState(undefined);


  /**
   * Data managers
   */
  const [passKey, setPassKey] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [freezeRendering, setFreezeRendering] = useState(false);
  const [freezeWorkloadChart, setFreezeWorkloadChart] = useState(true);
  const [stopDoughnutChartReRender, setStopDoughnutChartReRender] = useState(undefined);
  const [stopStatusChartReRender, setStopStatusChartReRender] = useState(false);
  const [stopPriorityChartReRender, setStopPriorityChartReRender] = useState(false);
  const [statusSelection, setStatusSelection] = useState([0, 1, 2, 3, 4]);
  const [prioritySelection, setPrioritySelection] = useState([0, 1, 2, 3]);
  //for Dashboard:
  const [dashBoardOrderIsFixed, setDashBoardOrderIsFixed] = useState(true);

  //for WorkloadChart:
  const [verifyWorkloadChart, setVerifyWorkloadChart] = useState(false);
  const [workloadMinutes, setWorkloadMinutes] = useState(0);
  const [suggestedWorkloadMinutes, setSuggestedWorkloadMinutes] = useState(0);

  //for DoughnutChart:
  const [verifyDoughnutChart, setVerifyDoughnutChart] = useState(undefined);
  const [verifyStatusChart, setVerifyStatusChart] = useState(undefined);
  const [verifyPriorityChart, setVerifyPriorityChart] = useState(undefined);

  const [weekStatusCount, setWeekStatusCount] = useState([]);
  const [weekPriorityCount, setWeekPriorityCount] = useState([]);
  const [thisWeekMinutesTotal, setThisWeeksMinutesTotal] = useState(undefined);
  const [thisWeekMinutesDone, setThisWeeksMinutesDone] = useState(undefined);
  const [percentageDone, setPercentageDone] = useState(undefined);
  const [personalNumberOfDoesNotFit, setPersonalNumberOfDoesNotFit] = useState(undefined);
  const [classNumberOfDoesNotFit, setClassNumberOfDoesNotFit] = useState(undefined);
  //for AssignnmentCharts
  // const [verifyAssignmentChart, setVerifyAssignmentChart] = useState(false);
  // const [verifyPersonalDeadlines, setVerifyPersonalDeadlines] = useState(false);
  const [additionalAssignments, setAdditionalAssignments] = useState(undefined);
  const [calculatingDeadlines, setCalculatingDeadlines] = useState(false);
  const [calculatingRecents, setCalculatingRecents] = useState(false);

  const [personalDeadlineAssignments, setPersonalDeadlineAssignments] =
    useState(undefined);
  const [classDeadlineAssignments, setClassDeadlineAssignments] = useState([]);
  const [topSix, setTopSix] = useState(undefined);


  /**
  * Notifiers
  */
  const [newUserAssignmentsChanges, setNewUserAssignmentsChanges] =
    useState(false);
  const [newClassAssignmentsChanges, setNewClassAssignmentsChanges] =
    useState(false);

  //SchoolYearData
  const [schoolYearData, setSchoolYearData] = useState(undefined);
  const [unresolvedSchoolYearData, setUnresolvedSchoolYearData] =
    useState(undefined);
  const [relevantUserAssignments, setRelevantUserAssignments] =
    useState(undefined);
  const [relevantClassAssignments, setRelevantClassAssignments] =
    useState(undefined);

  // useEffect(() => {
  //   // console.log("rerender...");
  //   console.log(relevantUserAssignments);
  // }, [relevantUserAssignments]);

  //set main freeze
  useEffect(() => {
    if (!dataLoading && referenceDate && selectedCourse) {
      // console.log("dataLoading starts from")
      setDataLoading(true);
    }
  }, [dataLoading, referenceDate, selectedCourse]);

  //load User Husibuech information
  useEffect(() => {
    const loadUserInformation = async () => {
      // console.log("...we load userData")
      setIsAStudent(currentUser.roles.includes("Student"));
      setIsATeacher(currentUser.roles.includes("Teacher"));
      setIsAnAdmin(currentUser.roles.includes("Admin"));
      setIsAReferenceStudent(currentUser.roles.includes("ReferenceStudent"));
      setIsALeader(
        currentUser.roles.includes("Teacher") ||
        currentUser.roles.includes("Admin")
      );
      try {
        await loadDataForUser(
          currentUser,
          setNumberOfFinishedExamStatus,
          setNumberOfFinishedHomeworkStatus,
          setShowActivationForm,
          setHolidays,
          setMyCoreCourses,
          setHusibuechTeachers,
          setHusibuechUsers,
          setHusibuechRoles,
          setHusibuechSubjects,
          setHusibuechSemesters,
          setHusibuechHolidays,
          setHusibuechCourses,
          setHusibuechCoreCourses,
          setUserSubjects,
          setUserCourses,
          setHusibuechExamTypes
        );
        if (currentUser.roles.includes("Student")) {
          setPersonalView(true); // students always have personal view in semesterTable, teachers can toggle
        }
        // setHusibuechLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (husibuechLoading && currentUser?.roles) {
      // console.log("newUser: ", currentUser)
      loadUserInformation();
    }
  }, [currentUser, husibuechLoading]);

  useEffect(() => {
    const awaitResolve = async () => {
      const newSchoolYearData = unresolvedSchoolYearData;
      // console.log("we have received new data", schoolYearData);
      setSchoolYearData(newSchoolYearData);
      if (husibuechLoading) {
        setHusibuechLoading(false);
      }
      setTimeout(async () => { }, 1500);
    };
    if (unresolvedSchoolYearData) {
      awaitResolve();
    }
  }, [unresolvedSchoolYearData]);

  useEffect(() => {
    if (dataLoading && schoolYearData) {
      // console.log("data loading: ", dataLoading)
      setFreezeRendering(true); //allows component updates
    }
  }, [dataLoading, schoolYearData]);

  //internal freezer
  useEffect(() => {
    if (freezeRendering) {
      setCalculatingDeadlines(true);
      setCalculatingRecents(true);
      setNewUserAssignmentsChanges(true);
      setNewClassAssignmentsChanges(true);
      //freezes the rendering of components
      setFreezeWorkloadChart(true);
      setStopDoughnutChartReRender(true);
    } else {
      // setCalculatingDeadlines(true);
      // setCalculatingRecents(true);
    }
  }, [freezeRendering]);
  //unfreeze
  useEffect(() => {
    if (freezeRendering && !calculatingDeadlines && !freezeWorkloadChart) {
      // console.log("unfreeze components")
      setFreezeRendering(!freezeRendering)
    }
  }, [freezeRendering, calculatingDeadlines]);


  /**
   * Get schoolYearData (first call)
   */
  useEffect(() => {
    const firstloadingOfSchoolYearData = async () => {
      try {
        const response = await userService.getUserInfo(
          "schoolyeardata",
          currentUser.id,
          selectedCourse?.id
        );
        //check what the current semester is.
        if (
          moment(new Date()).isBefore(
            moment(new Date(response.data.datesFirstSemester[1]))
          )
        ) { //we are in the first semester
          await setCurrentSemesterStartDate(
            response.data.datesFirstSemester[0]
          );
          await setCurrentSemesterEndDate(response.data.datesFirstSemester[1]);
        } else { //we are in the second semester
          await setCurrentSemesterStartDate(
            response.data.datesSecondSemester[0]
          );
          await setCurrentSemesterEndDate(response.data.datesSecondSemester[1]);
        }
        // console.log("Schoolyear Data arrived");
        // console.log(response.data);
        // console.log(currentUser)
        await setUnresolvedSchoolYearData(response.data);
      } catch (err) {
        console.error("there was an error", err);
      }
    };
    if (dataLoading) {
      firstloadingOfSchoolYearData(); //selected Course is defined once user courses arrive
    }
  }, [dataLoading]);

  /**
 * For Admin and Teacher - Fix a selected course for teachers and admins on log in
 */
  useEffect(() => {
    if (isALeader && myCoreCourses?.length > 0) {
      setMySelectedCoreCourse(myCoreCourses[0]);
    } else if (isAStudent && myCoreCourses?.length === 0) {
      console.log("we do this", myCoreCourses);
      setShowActivationForm(true);
    }
    // console.log(myCoreCourses)
  }, [myCoreCourses]);

  useEffect(() => {
    if (userCourses) {
      setSelectedCourse(userCourses[0])
      if (isATeacher && currentUser?.abbreviation === null) {
        setShowActivationForm(true);
      }
    }
  }, [userCourses]);


  // helper functions
  const setRelevantSchoolYearAssignments = async () => {
    /**
     * create a relevantWeekAssignmentsObject for schoolYear
     */
    const newUserAssignmentsForThisWeek = await getRelevantAssignmentsObject(
      schoolYearData,
      showExams,
      showHomeworks,
      showPersonals,
      statusSelection,
      prioritySelection,
      referenceDate,
      true
    );

    const updateData = await updateDataCheck(
      relevantUserAssignments,
      newUserAssignmentsForThisWeek,
      selectedCourse,
      oldSelectedCourse,
      passKey,
      "personal"
    );
    if (updateData) {
      // console.log("enter the holy grail")
      if (passKey) {
        await setPassKey(false);
      }
      setVerifyWorkloadChart(true);
      await setVerifyDoughnutChart(true);
      // setVerifyAssignmentChart(true);
      await setRelevantUserAssignments(newUserAssignmentsForThisWeek);
      await setNewUserAssignmentsChanges(false);
      //this needs to be done if a user is a student
      //(i.e. doe not have a coreCourseData)
      if (isAStudent) {
        await setNewClassAssignmentsChanges(false);
      }
    } else {
      await setNewUserAssignmentsChanges(false);
    }
  };

  const setRelevantCoreCourseYearAssignments = async () => {
    //this also defines a user who teaches a coreCourse!
    // console.log("we come here...")
    const newClassAssignmentsForThisWeek = await getRelevantAssignmentsObject(
      schoolYearData,
      showExams,
      showHomeworks,
      showPersonals,
      statusSelection,
      prioritySelection,
      referenceDate,
      false
    );
    // console.log("This is the new relevantCoreCourseWeekAssignment object:");
    const updateData = await updateDataCheck(
      relevantClassAssignments,
      newClassAssignmentsForThisWeek,
      selectedCourse,
      oldSelectedCourse,
      passKey,
      "coreCourse"
    );
    if (updateData) {
      // console.log("data has changed...");
      // setVerifyAssignmentChart(true);
      await setVerifyWorkloadChart(true);
      await setVerifyDoughnutChart(true);
      // console.log("newClassAss ", newClassAssignmentsForThisWeek);
      await setRelevantClassAssignments(newClassAssignmentsForThisWeek);
      await setNewClassAssignmentsChanges(false);
    } else {
      await setNewClassAssignmentsChanges(false);
    }
  };

  /**
  * set the relevant Week and CoreCourse assignments for user
  */
  useEffect(() => {
    // console.log("NewChanges: ", newUserAssignmentsChanges, " ", newClassAssignmentsChanges);

    const checkForUpdates = async () => {
      // console.log(referenceDate);
      try {
        // console.log("we check for data");
        // Ensure schoolYearData is resolved before proceeding
        const resolvedSchoolYearData = await schoolYearData;
        // console.log("our unresolved data is: ", resolvedSchoolYearData);

        await setRelevantSchoolYearAssignments(resolvedSchoolYearData);
        // console.log("we came 633");
        await setRelevantCoreCourseYearAssignments(resolvedSchoolYearData);

        // console.log("we came 635");
      } catch (error) {
        console.error("Error while processing updates:", error);
      }
    };
    if (
      currentUser &&
      schoolYearData &&
      newUserAssignmentsChanges &&
      newClassAssignmentsChanges
    ) {
      checkForUpdates();
    }
  }, [currentUser, schoolYearData, newUserAssignmentsChanges, newClassAssignmentsChanges]);

  useEffect(() => {
    const clearHusibuech = () => {
      setTimeout(async () => {
        setIsAStudent(false);
        setIsATeacher(false);
        setIsAnAdmin(false);
        setIsAReferenceStudent(false);
        setIsALeader(false);
        setHolidays([]);  // Assuming it's an array
        setMyCoreCourses([]);  // Assuming it's an array
        setHusibuechTeachers([]);  // Assuming it's an array
        setHusibuechUsers([]);  // Assuming it's an array
        setHusibuechRoles([]);  // Assuming it's an array
        setHusibuechSubjects([]);  // Assuming it's an array
        setHusibuechSemesters([]);  // Assuming it's an array
        setHusibuechHolidays([]);  // Assuming it's an array
        setHusibuechCourses([]);  // Assuming it's an array
        setHusibuechCoreCourses([]);  // Assuming it's an array
        setUserSubjects([]);  // Assuming it's an array
        setUserCourses(undefined);  // Assuming it's an array
        setHusibuechExamTypes([]);  // Assuming it's an array
        setPersonalView(false)
        setCurrentSemesterStartDate("");
        setCurrentSemesterEndDate("");
        setCurrentSemesterName("");
        setSelectedCourse(undefined)
        setSchoolYearData(undefined);
        setRelevantUserAssignments(undefined);
        setRelevantClassAssignments(undefined);

      }, 1000);
    };

    if (loggingOut) {
      clearHusibuech()
    }
  }, [loggingOut]);


  return (
    <div className="content" sx={{ clear: "left" }}>
      <HusibuechContext.Provider
        value={{
          isAStudent,
          isATeacher,
          isAnAdmin,
          isAReferenceStudent,
          isALeader,
          orderIsFixed,
          setOrderIsFixed,
          husibuechExamTypes,
          userCourses,
          selectedCourse,
          numberOfFinishedExamStatus,
          setNumberOfFinishedExamStatus,
          numberOfFinishedHomeworkStatus,
          setNumberOfFinishedHomeworkStatus,
          showActivationForm,
          setShowActivationForm,
          holidays,
          setHolidays,
          myCoreCourses,
          setMyCoreCourses,
          husibuechTeachers,
          setHusibuechTeachers,
          husibuechUsers,
          setHusibuechUsers,
          husibuechRoles,
          setHusibuechRoles,
          husibuechSubjects,
          setHusibuechSubjects,
          husibuechSemesters,
          setHusibuechSemesters,
          husibuechHolidays,
          setHusibuechHolidays,
          husibuechCourses,
          setHusibuechCourses,
          husibuechCoreCourses,
          setHusibuechCoreCourses,
          userSubjects,
          setUserSubjects,
          userCourses,
          setUserCourses,
          husibuechExamTypes,
          setHusibuechExamTypes,
          personalView,
          setPersonalView,
          currentSemesterStartDate,
          setCurrentSemesterStartDate,
          currentSemesterEndDate,
          setCurrentSemesterEndDate,
          currentSemesterName,
          setCurrentSemesterName,
          schoolYearData,
          relevantUserAssignments,
          relevantClassAssignments,
          calculatingDeadlines, calculatingRecents,
          setCalculatingDeadlines,
          topSix,
          setTopSix,
          personalDeadlineAssignments,
          setPersonalDeadlineAssignments,
          classDeadlineAssignments, setClassDeadlineAssignments,
          additionalAssignments,
          setAdditionalAssignments,
          workloadMinutes, setWorkloadMinutes,
          suggestedWorkloadMinutes, setSuggestedWorkloadMinutes,
          verifyWorkloadChart, setVerifyWorkloadChart,
          setFreezeWorkloadChart,
          personalNumberOfDoesNotFit, setPersonalNumberOfDoesNotFit,
          classNumberOfDoesNotFit, setClassNumberOfDoesNotFit,
          showHomeworks, showExams, showPersonals, prioritySelection, statusSelection,
          husibuechUserKurse, setHusibuechUserKurse,
        }}
      >
        {/* THEMED APP */}
        <PageTemplate type={"husibuech"}>
          <><ActivationForm />

            {!husibuechLoading ?
              <HusibuechContent /> : <LoadingDashboardContent />}
          </>
        </PageTemplate>

      </HusibuechContext.Provider>

    </div>
  );
}

export default memo(Husibuech);
