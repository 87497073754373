import React, { memo, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useTheme } from "@mui/styles";
import WorkloadChart from "./WorkloadChart";
import YearView from "./YearView/YearView";

function WorkloadFlip() {
  const theme = useTheme();
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div style={{ background: theme.palette.background.paper }}>
        <WorkloadChart
          setWorkloadFlip={handleClick}
          workloadFlip={isFlipped}
        />
      </div>

      <div style={{ background: theme.palette.background.paper }}>
        <YearView
          setWorkloadFlip={handleClick}
          workloadFlip={isFlipped}        />
      </div>
    </ReactCardFlip>
  );
}

export default memo(WorkloadFlip);