import React from "react";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";
import BoxHeader from "../../../../4_generalHelpers/1_Boxes/BoxHeader";
import MiddleBox from "../1_Boxes/MiddleBox";
import DeadlineAssignmentsList from "../4_DeadlineBox/DeadlineAssignmentsList";
import { createTeacherName } from "../../../../4_generalHelpers/4_Text/nameHelpers";

const RecentlyAdded = () => {
  const {
    isALeader,
    selectedCourse,
  } = useHusibuechContext();

  return (
    <>
      <BoxHeader
        title={
          isALeader && selectedCourse
            ? `Kürzlich der ${selectedCourse?.name ? createTeacherName(selectedCourse.name) : "Klasse"} hinzugefügt`
            : "Neue Aufgaben"
        }
      />
      <MiddleBox>
        <DeadlineAssignmentsList
          type={"topSix"}
        // assignments={memoizedTopSix}
        />
      </MiddleBox>
    </>
  );
};

export default RecentlyAdded;
