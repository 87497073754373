import React, { memo } from "react";
import { useAppContext } from "../../../../8_contexts/contextLib";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";
import BoxHeader from "../../../../4_generalHelpers/1_Boxes/BoxHeader";
import MiddleBox from "../1_Boxes/MiddleBox";
import AssignmentAddCard from "./AssignmentAddCard";

function AssignmentAddBox({ flipAssignment, setFlipAssignment, type, date, handleClose, boxType, single }) {
  const { currentUser } = useAppContext();
  const { isALeader,isAReferenceStudent } = useHusibuechContext();
  
  return (
    <>
      <BoxHeader
        title={"Neues Assignment erfassen"}
        options={!type && !single ? ["flipAssignment"] : []}
        flipAssignment={flipAssignment} setFlipAssignment={setFlipAssignment}
      />
      <MiddleBox>
        {currentUser && <AssignmentAddCard handleClose={handleClose} date={date} restricted={!(isALeader || isAReferenceStudent)}/>}
      </MiddleBox>
    </>
  );
}
export default memo(AssignmentAddBox);
