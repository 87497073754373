import React, { memo, useEffect, useState } from "react";
import AssignmentAvatar from "./AssignmentAvatar";
import { Avatar } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useHusibuechContext } from "../../../../../../8_contexts/husibuechContext";
import StyledTooltip from "../../../../../../4_generalHelpers/3_Tooltips/StyledTooltip";

function AssignmentListItem({ assignment }) {
  const theme = useTheme();
  const { isALeader, isAReferenceStudent, userCourses } = useHusibuechContext();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [currentAssignment, setCurrentAssignment] = useState(assignment);

  const isPersonalType = currentAssignment.type === "personal";
  const viewIsNotRestricted =
    (isALeader || isAReferenceStudent) &&
    (isPersonalType ||
      (userCourses &&
        userCourses.some(
          (course) => course.id === currentAssignment.course_id
        )));

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // Update currentAssignment when assignment prop changes
  useEffect(() => {
    // console.log(assignment)
    setCurrentAssignment(assignment);
  }, [assignment]);

  const tooltipTitle = viewIsNotRestricted
    ? "Assignment bearbeiten"
    : "Details ansehen";

  const backgroundColor =
    currentAssignment.status === 4
      ? theme.palette.levels.green
      : currentAssignment.status === 3
      ? theme.palette.levels.lightGreen
      : currentAssignment.status === 2
      ? theme.palette.levels.yellow
      : currentAssignment.status === 1
      ? theme.palette.levels.orange
      : theme.palette.levels.red;

  return (
    <>
      <StyledTooltip title={tooltipTitle} placement="bottom-start" arrow>

        <Avatar
          sx={{
            backgroundColor: backgroundColor,
            height: 35,
            width: 45,
            marginTop: "-0.1em",
            marginRight: "0.4em",
            // paddingLeft: "-0.4em",
            borderRadius: 3,
            cursor: "pointer",
          }}
        >
          <AssignmentAvatar type={currentAssignment.type} marginTop={"0em"} marginLeft={"-0.2em"} marginRight={"-0em"} />
        </Avatar>
        {/* </ListItemAvatar> */}
      </StyledTooltip>
    </>
  );
}

export default memo(AssignmentListItem);
