import { memo, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";
import AssignmentAddBox from "../2_AssignmentAdd/AssignmentAddBox";
import DeadlineAssignments from "./DeadlineAssignments";

function PersonalDeadlineBox({single}) {
  const { selectedCourse, isAStudent, isALeader } = useHusibuechContext();
  const [flipAssignment, setFlipAssignment] = useState(true);
  // Early return when currentUser or selectedCourse is not available
  if (isALeader && !selectedCourse) {
    return null; // or provide alternative UI for users without roles
  }

  const assignmentBoxProps = {
    flipAssignment,
    setFlipAssignment,
  };

  // console.log("single,", single)
  return (
    <ReactCardFlip isFlipped={flipAssignment} flipDirection="horizontal">
      <AssignmentAddBox {...assignmentBoxProps} boxType={"add"} single={single}/>

      <DeadlineAssignments
        type={isAStudent ? "additional" : "personal"}
        {...assignmentBoxProps}
        single={single}
      />
    </ReactCardFlip>
  );
}

export default memo(PersonalDeadlineBox);

