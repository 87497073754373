import { MenuItem } from "@mui/material";
import { memo } from "react";
import { useAppContext } from "../../../../../8_contexts/contextLib";
import { CustomTextField } from "./CustomTextField";
import { useTheme } from "@mui/styles";
import { useHusibuechContext } from "../../../../../8_contexts/husibuechContext";

function CourseBoxComponent({
  restricted,
  selectedAssignmentCourse,
  change,
}) {
  const {appInDarkMode } = useAppContext();
  const {userCourses} = useHusibuechContext();
  const theme = useTheme()

  return (
    <>
      <CustomTextField
        // labelId="course-lable"
        // defaultValue={}
        id="course"
        disabled={restricted}
        select
        onChange={change}
        value={selectedAssignmentCourse?.name}
        label="Kurs"
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        paper={theme.palette.background.paper}
        white={theme.palette.levels.white}
        dark={theme.palette.levels.dark}
      >
        <MenuItem
          disabled={false}
          key={selectedAssignmentCourse.id}
          value={selectedAssignmentCourse.name}
          style={{ fontSize: "0.8rem" }}
        >
          {selectedAssignmentCourse.name}
        </MenuItem>
        {userCourses?.filter(
          (course) => course.id !== selectedAssignmentCourse.id
        ).length > 0 &&
          userCourses
            .filter((course) => course.id !== selectedAssignmentCourse.id)
            .map((course, i) => (
              <MenuItem style={{ fontSize: "0.8rem" }} key={i} value={course}>
                {course?.name?course.name:"hello"}
              </MenuItem>
            ))}
      </CustomTextField>
    </>
  );
}

export default memo(CourseBoxComponent);
