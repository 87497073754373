import React, { memo } from "react";
import { useAppContext } from "../../../../../8_contexts/contextLib";
import { CustomTextField } from "./CustomTextField";
import ErrorBox from "./ErrorBox";
import { useTheme } from "@mui/styles";

function CountingFactorBoxComponent({
  restricted,
  countingFactor,
  countingFactorErr,
  change,
}) {
  // console.log("count...", countingFactor)
  const theme = useTheme()
  const {appInDarkMode} = useAppContext()
  return (
    <>
      <CustomTextField
        id="countingFactor"
        type="number"
        disabled={restricted}
        onChange={change}
        value={countingFactor}
        label="Gewicht"
        inputProps={{
          style: { fontSize: "0.9em" },
          step: 0.1, // Set the step size to 0.1
        }}
        appindarkmode={appInDarkMode.toString()}
        paper={theme.palette.background.paper}
        white={theme.palette.levels.white}
        dark={theme.palette.levels.dark}
      />
      <ErrorBox error={countingFactorErr} />
    </>
  );
}

export default memo(CountingFactorBoxComponent);
