import React, { memo } from "react";
import { useAppContext } from "../../../../../8_contexts/contextLib";
import { CustomTextField } from "./CustomTextField";
import { useTheme } from "@mui/styles";

function NameBoxComponent({ restricted, assignmentName, change }) {
  const { appInDarkMode } = useAppContext()
  const theme = useTheme();

  return (
    <>
      {/* Assignment Name */}
      <CustomTextField
        required
        id="name-box"
        label="Name"
        disabled={restricted}
        value={assignmentName}
        onChange={change}
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        paper={theme.palette.background.paper}
        white={theme.palette.levels.white}
        dark={theme.palette.levels.dark}
      />
    </>
  );
}

export default memo(NameBoxComponent);
