import React, { memo } from "react";
import { MenuItem } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../../../8_contexts/contextLib";
import { CustomTextField } from "./CustomTextField";

function TypeBoxComponent({ restricted, type, change }) {
  // console.log(type)
  const theme = useTheme()
  const { appInDarkMode } = useAppContext()
  const types = [
    {
      value: "homework",
      label: "Hausaufgabe",
    },
    {
      value: "exam",
      label: "Prüfung",
    },
    {
      value: "personal",
      label: "Persönlich",
    },
  ];

  return (
    <>
      <CustomTextField
        id="type-box"
        select
        disabled={restricted}
        onChange={change}
        value={type}
        label="Typ"
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        paper={theme.palette.background.paper}
        white={theme.palette.levels.white}
        dark={theme.palette.levels.dark}
      >
        {
          types.map((option, i) => (
            <MenuItem
              key={i}
              value={option.value}
              style={{ fontSize: "0.8rem" }}
            >
              {option.label}
            </MenuItem>
          ))}
      </CustomTextField>
    </>
  );
}

export default memo(TypeBoxComponent);
