import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
} from "@mui/material";
import { memo } from "react";
import { useTheme } from "@mui/styles";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";
import AssignmentAvatar from "./1_DeadlineHelpers/2_List/AssignmentAvatar";

function EmptyList({ type }) {
  const theme = useTheme();
  const commonTextStyle = { color: theme.palette.text.primary };
  const { selectedCourse, isAStudent } = useHusibuechContext();

  const getAssignmentPrimaryText = () => (
    <Typography variant="body2" style={commonTextStyle}>
      Keine Assignments
    </Typography>
  );

  const getAssignmentSecondaryText = () => {
    switch (type) {
      case "personal":
        return (
          <Typography variant="body2" style={commonTextStyle}>
            Möchtest du dir etwas einplanen?
          </Typography>
        );

      case "additional":
        return (
          <Typography variant="body2" style={commonTextStyle}>
            {isAStudent
              ? "Du könntest dir spätere Assignments"
              : "Sie könnten sich spätere Assignments"}
            <br />
            im Workboard einplanen
          </Typography>
        );
      case "class":
        return (
          <Typography variant="body2" style={commonTextStyle}>
            {selectedCourse
              ? "Wollen Sie der Klasse was aufgeben?"
              : "Siehe Systemeinstellungen"}
          </Typography>
        );

      case "topSix":
        return (
          <Typography variant="body2" style={commonTextStyle}>
            in der letzten Woche hinzugefügt
          </Typography>
        );

      default:
        return null;
    }
  };

  return (
    <List dense>
      <ListItem>
      <Avatar
          sx={{
            backgroundColor: theme.palette.levels.green,
            height: 35,
            width: 45,
            marginTop: "-0.1em",
            marginRight: "0.4em",
            // paddingLeft: "-0.4em",
            borderRadius: 3,
            cursor: "pointer",
          }}
        >
        {/* <ListItemAvatar
          sx={{
            height: 35,
            // width: "14%",
            paddingRight: -3,
            marginTop: -0.5,
            marginRight: 1,
            paddingLeft: "-6px",
            borderRadius: 3,
          }}
        > */}
          <AssignmentAvatar type="finished" marginTop={"0em"} marginLeft={"-0.2em"} marginRight={"-0em"}/>
        </Avatar>
        <ListItemText
          primary={getAssignmentPrimaryText()}
          secondary={getAssignmentSecondaryText()}
        />
      </ListItem>
    </List>
  );
}

export default memo(EmptyList);
