const { default: appService } = require("../2_Services/app.service");
const { default: userService } = require("../2_Services/user.service");

async function getUserData(type, currentUser) {
  try {
    switch (type) {
      case "systemcourses":
      case "core_course":
      case "holiday":
      case "exam_type":
      case "teachers":
      case "users":
      case "role":
      case "subject":
      case "semester":
        return (await appService.get(type)).data;
      case "subjects":
      case "courses":
      case "corecourse":
        return (await userService.getUserInfo(type, currentUser.id)).data;
      default:
        return [];
    }
  } catch (err) {
    console.error(err);
    return [];
  }
}

exports.getUserData = getUserData;
