import React, { memo } from "react";

import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";
import { Grid2 } from "@mui/material";
import TypeBoxComponent from "./1_CardElements/TypeBoxComponent";
import NameBoxComponent from "./1_CardElements/NameBoxComponent";
// import ErrorBox from "../CardElements/ErrorBox";

function LineOne({ type, onChangeType, assignmentName, onChangeAssignmentName, restricted }){
    const {userCourses} = useHusibuechContext()
    return (
        <Grid2
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{ marginTop: "4px" }}
        >
            {/* Type Container */}
            <Grid2 xs={4}>
                {type && <TypeBoxComponent
                    restricted={restricted}
                    type={type}
                    userCourses={userCourses}
                    change={onChangeType}
                />}
            </Grid2>

            {/* Assignment Name Container */}
            <Grid2 xs={7}>
                <NameBoxComponent
                    restricted={false}
                    assignmentName={assignmentName}
                    change={onChangeAssignmentName}
                />
            </Grid2>
        </Grid2>

    );
}

export default memo(LineOne);
