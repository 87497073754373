const { createTeacherName } = require("../4_Text/nameHelpers");

function getBoxHeaderTitle(
  isAStudent,
  isATeacher,
  isAnAdmin,
  selectedCourse,
  type,
  showCourseAssignmentOnly
) {
  const courseView = type === "coreCourse";
  const personalView = type === "personal" || type === "studentView"
  const additionalView = type === "additional"


  switch (true) {
    case (
      isAStudent && personalView
    ):
      return "Deine Deadlines diese Woche";

    case (
      (isAStudent || isATeacher || isAnAdmin) && additionalView
    ):
      return "Anstehende & Geplante Assignments";
    case (
      isATeacher && personalView
    ):
      return "Ihre Deadlines diese Woche";

    case (
      isATeacher && courseView
    ):
      return showCourseAssignmentOnly
        ? `Deadlines im Kurs ${selectedCourse?.name || ""}`
        : isAnAdmin
          ? `Deadlines der Klasse ${selectedCourse?.name ? createTeacherName(selectedCourse.name) : ""}`
          : `Deadlines im Kurs ${selectedCourse?.name || ""}`;

    default:
      return "Es gibt keine Klassen";
  }
}

exports.getBoxHeaderTitle = getBoxHeaderTitle;
