import {
  FlipCameraAndroid,
  Visibility,
} from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { memo, useState } from "react";

import { useTheme } from "@mui/styles";
import CustomWidthTooltip from "../3_Tooltips/CustomWidthTooltip";
import longText from "../4_Text/LongText";
import StyledTooltip from "../3_Tooltips/StyledTooltip";

function BoxHeader({
  title,
  options,
  flipAssignment,
  setFlipAssignment,
  workloadFlip,
  setWorkloadFlip,
}) {
  const theme = useTheme();
  const [showDetail, setShowDetail] = useState(false);

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  const flipAssignmentView = () => {
    setFlipAssignment(!flipAssignment);
  };

  const workloadFlipView = () => {
    setWorkloadFlip(!workloadFlip);
  };


  return (
    <>
      <Typography variant="h4" color={theme.palette.text.blue} noWrap={true}>
        {title}

        {options?.includes("detail") && (
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            style={{ marginBottom: "0.1em" }}
            onClick={() => {
              toggleShowDetail();
            }}
          >
            <CustomWidthTooltip title={longText} placement="bottom" arrow>
              <Visibility style={{ color: theme.palette.icon.primary }} />
            </CustomWidthTooltip>
          </IconButton>
        )}
        {options?.includes("flipAssignment") && (
          <StyledTooltip
            title={
              flipAssignment
                ? "Assignment hinzufügen"
                : "Zusatzassignments verwalten?"
            }
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={flipAssignmentView}
            >
              <FlipCameraAndroid
                style={{ color: theme.palette.icon.primary }}
              />
            </IconButton>
          </StyledTooltip>
        )}
         {options?.includes("workloadFlip") && (
          <StyledTooltip
            title={
              workloadFlip
                ? "Wochenbelastung sehen"
                : "Jahresbelastung sehen"
            }
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={workloadFlipView}
            >
              <FlipCameraAndroid
                style={{ color: theme.palette.icon.primary }}
              />
            </IconButton>
          </StyledTooltip>
        )}


      </Typography>
    </>
  );
}
export default memo(BoxHeader);
