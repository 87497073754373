import { filterUniqueAssignments } from "../../4_DeadlineBox/1_DeadlineHelpers/4_Functions/filterUniqueAssignments";
import { filterTasksByCourse } from "../../4_DeadlineBox/1_DeadlineHelpers/filterTasksByCourse";

export const updateRelevantClassAssignmentsForChart = async (
  relevantClassAssignments, showCourseAssignmentOnly, selectedCourse
) => {
  //update this week
  let thisWeeksAssignments = relevantClassAssignments.thisWeek;
  let filteredAssignments = await filterUniqueAssignments(thisWeeksAssignments, 'assignment_id');
  if (selectedCourse) {
    filteredAssignments = filteredAssignments.filter((assignments) => assignments.core_course_id === selectedCourse.core_course_id)
  }
  if(showCourseAssignmentOnly){
    filteredAssignments = await filterTasksByCourse(
      selectedCourse,
      filteredAssignments
    );
  }

  relevantClassAssignments.thisWeek = filteredAssignments;

  //update additional
  let thisWeeksAdditionalAssignments = relevantClassAssignments.additional.daysToPrepare
  filteredAssignments = await filterUniqueAssignments(thisWeeksAdditionalAssignments, 'assignment_id');
  if (selectedCourse) {
    filteredAssignments = filteredAssignments.filter((assignments) => assignments.core_course_id === selectedCourse.core_course_id)
  }
  if(showCourseAssignmentOnly){
    filteredAssignments = await filterTasksByCourse(
      selectedCourse,
      filteredAssignments
    );
  }
  relevantClassAssignments.additional.daysToPrepare = filteredAssignments;

  //update extraDates
  let thisWeeksExtraAssignments = relevantClassAssignments.additional.extraDates
  filteredAssignments = await filterUniqueAssignments(thisWeeksExtraAssignments, 'assignment_id');
  if (selectedCourse) {
    filteredAssignments = filteredAssignments.filter((assignments) => assignments.core_course_id === selectedCourse.core_course_id)
  }
  if(showCourseAssignmentOnly){
    filteredAssignments = await filterTasksByCourse(
      selectedCourse,
      filteredAssignments
    );
  }
  relevantClassAssignments.additional.extraDates = filteredAssignments;

  return relevantClassAssignments;
};
