import { Typography } from "@mui/material";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { useAppContext } from "../../8_contexts/contextLib";

function DashboardContent() {
  const {setHusibuechLoading} = useAppContext()
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Functions to handle navigation on click
  const handleClickChatApp = () => {
    navigate("/chatApp"); // Navigate to the "app1" page
  };

  const handleClickHusibuech = () => {
    setHusibuechLoading(true)
    navigate("/husibuech"); // Navigate to the "app2" page
  };

  return (
    <div
      style={{
        display: "flex",         // Use flexbox for layout
        flexDirection: "column", // Stack items vertically (from top to bottom)
        alignItems: "center",    // Center the items horizontally
        justifyContent: "center", // Center the items vertically
        height: "20rem",         // Ensure the container has a fixed height
        gap: "3rem",             // Add gap between the items
      }}
    >
      <div>
        <Typography color="primary" variant="h4" fontWeight={"bold"}>
          Diese Applikationen sind in Entwicklung:
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          gap: "2rem", // Gap between the app circles
        }}
      >
        {/* ChatApp */}
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={handleClickChatApp} // Handle click
        >
          <div
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              backgroundImage: "url('./static/images/team/apeFather.png')", // Replace with your image path
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div>
            <Typography color="textSecondary" variant="h6" style={{ marginTop: "0.5rem" }}>
              ChatApp
            </Typography>
          </div>

        </div>

        {/* Husibuech */}
        <div
          style={{
            display: "flex",         // Use flexbox for centering
            flexDirection: "column", // Stack image and text vertically
            alignItems: "center",    // Horizontally center image and text
            justifyContent: "center", // Vertically center image and text
            cursor: "pointer",       // Change cursor to pointer on hover
          }}
          onClick={handleClickHusibuech} // Handle the click event
        >
          <div
            style={{
              width: "100px",          
              height: "100px",
              borderRadius: "50%",
              backgroundImage: "url('./static/images/login/logo512.png')", // Path to the image
              backgroundSize: "cover", // Ensure the image covers the div
              backgroundPosition: "center", // Center the image inside the div
            }}
          ></div>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: "0.5rem" }}>
            Hausaufgaben Planen
          </Typography>
        </div>
      </div>
    </div >
  );
}

export default memo(DashboardContent);
