import moment from "moment";
import dataService from "../../../HusibuechHelpers/2_Services/data.service";

export const getRelevantAssignmentsObject = async (
  schoolYearData,
  showExams,
  showHomeworks,
  showPersonals,
  statusSelection,
  prioritySelection,
  referenceDate,
  personalView
) => {
  const assignmentsArray = personalView
    ? schoolYearData?.userAssignments
    : schoolYearData?.classAssignments;

  if (assignmentsArray?.length > 0) {
    if (!personalView) {
      // console.log("classarray, ", assignmentsArray)
    }
    try {
      const filteredArray = []
        .concat(
          showExams
            ? dataService.getFutureAssignments(
              dataService.getAssignments(
                assignmentsArray,
                "exam",
                statusSelection,
                prioritySelection
              ),
              referenceDate
            )
            : []
        )
        .concat(
          showHomeworks
            ? dataService.getFutureAssignments(
              dataService.getAssignments(
                assignmentsArray,
                "homework",
                statusSelection,
                prioritySelection
              ),
              referenceDate
            )
            : []
        )
        .concat(
          personalView && showPersonals
            ? dataService.getFutureAssignments(
              dataService.getAssignments(
                schoolYearData.userAssignments,
                "personal",
                statusSelection,
                prioritySelection
              ),
              referenceDate
            )
            : []
        );

      const assignmentsUntil =
        filteredArray.length > 0
          ? await dataService.getAssignmentsUntil(filteredArray, referenceDate) //due_date end of week
          : [];

      if (!personalView) {
        // console.log("classarray, ", assignmentsUntil)
      }
      const additionalRelevant =
        filteredArray.length > 0
          ? await dataService.getAdditionalRelevantBydue_date(
            filteredArray,
            referenceDate,
            personalView
              ? "my_days"
              : "suggested_days"
          )
          : [];

      let extraDates = [];
      let extrafilteredArray = [];
      if (personalView && filteredArray.length > 0) {
        extrafilteredArray = filteredArray.filter(
          (el) => !assignmentsUntil.concat(additionalRelevant).includes(el) //removes additional relevant
        );

        extraDates = dataService.getAdditionalRelevantByExtraDates( //gets the elements that have been additionally planned in by user
          extrafilteredArray,
          referenceDate,
          "my_days"
        );
      }

      let object = {
        referenceDate: moment(referenceDate).startOf("isoWeek").format("YYYY-MM-DD"),
        thisWeek: assignmentsUntil,
        additional: {
          daysToPrepare: additionalRelevant,
          extraDates: extraDates,
        },
        verifiedChart: false,
        verifiedDoughnut: false,
      };
      if (!personalView) {
        // console.log("classarray, ", object)
      }
      return object
    } catch (err) {
      console.error(err);
    }
  }
  //null return on empty users
  // console.log("we must exit")
  return dataService.getRelevantWeekAssignments(
    referenceDate,
    [],
    [],
    []
  );
};
