import React, { memo } from "react";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../../../8_contexts/contextLib";
import { CustomTextField } from "./CustomTextField";

function DaysToPrepareBoxComponent({
  classView,
  restricted,
  my_days,
  suggested_days,
  type,
  personalView,
  change,
}) {
  const theme = useTheme()
  const {appInDarkMode} = useAppContext()
  return (
    <>
      <CustomTextField
        id={personalView ? "myVorbereitung" : "prepdays"}
        placeholder={personalView ? "Tage" : "Anz. Tage"}
        type="number"
        fullWidth
        // style={{backgroundColor: "green"}}
        appindarkmode={appInDarkMode.toString()}
        paper={theme.palette.background.paper}
        white={theme.palette.levels.white}
        dark={theme.palette.levels.dark}

        label={
          (personalView || type === "personal") && my_days === 0
            ? "MyPrep Heute"
            : personalView || type === "personal"
              ? "MyPrep Days"
              : (!personalView || type !== "personal") &&
                suggested_days === 0
                ? "Heute SuS"
                : !personalView || type !== "personal"
                  ? "PrepDays SuS"
                  : "PrepDays SuS"
        }
        // helperText={personalView ? "" : !restricted ? "(Anzahl Tage)" : ""}
        variant="outlined"
        // required
        autoFocus={false}
        disabled={classView && restricted}
        value={
          my_days === undefined ||
            suggested_days === undefined || isNaN(suggested_days)
            ? 0
            : personalView || type === "personal"
              ? my_days
              : (restricted && my_days === -1) || classView && restricted
                ? suggested_days
                : restricted
                  ? my_days
                  : suggested_days
        }
        onChange={
          personalView || type === "personal"
            ? (e) => change(e, false)
            : restricted
              ? (e) => change(e, false)
              : (e) => change(e, true)
        }
        inputProps={{ style: { fontSize: "0.9em" } }}
      />
    </>
  );
}

export default memo(DaysToPrepareBoxComponent);
