import React, { memo, useState, useEffect, useRef } from "react";
import moment from "moment";
import "moment/locale/de";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";
import { useAppContext } from "../../../../8_contexts/contextLib";
import workloadService from "../../HusibuechHelpers/2_Services/workload.service";
import BoxHeader from "../../../../4_generalHelpers/1_Boxes/BoxHeader";
import MiddleBox from "../1_Boxes/MiddleBox";
import { createTeacherName } from "../../../../4_generalHelpers/4_Text/nameHelpers";
import { calculateFitsInWeek } from "./_WorkloadChartHelpers/calculateFitsInWeek";
import { calculateWorkloadMinutesChart } from "./_WorkloadChartHelpers/calculateWorkloadMinutesChart";
import { getRelevantAssignmentsByDay } from "./_WorkloadChartHelpers/getRelevantAssignmentsByDay";
import { updateRelevantClassAssignmentsForChart } from "./_WorkloadChartHelpers/updateRelevantClassAssignmentsForChart";
import WorkloadFitsInComponent from "./WorkloadFitsInComponent";
import WorkloadChartComponent from "./WorkloadChartComponent";

// import WorkloadChartComponent from "./WorkloadChartComponent";
moment.locale("de");

function WorkloadChart({ workloadFlip, setWorkloadFlip }) {
  const [calibratingWorkload, setCalibratingWorkload] = useState(false);
  const { currentUser } = useAppContext()
  const {
    isAStudent,
    isALeader,
    relevantClassAssignments,
    relevantUserAssignments,
    selectedCourse,
    referenceDate,
    setFreezeWorkloadChart,
    workloadMinutes,
    setWorkloadMinutes,
    suggestedWorkloadMinutes,
    setSuggestedWorkloadMinutes,
    setPersonalNumberOfDoesNotFit,
    setClassNumberOfDoesNotFit,
    verifyWorkloadChart,
    setVerifyWorkloadChart,
    showCourseAssignmentOnly,
    isMobile,
  } = useHusibuechContext();

  const [maxMinutesDay, setMaxMinutesDay] = useState(0);
  const [personalWorkWeekData, setPersonalWorkWeekData] = useState([60, 60, 60, 60, 60, 60, 60]);     //define a class workWeek!
  const [height, setHeight] = useState(300)
  const containerRef = useRef(null);


  useEffect(() => {
    const adjustFontSize = async () => {
      const containerHeight = containerRef.current.clientHeight; // Use clientHeight
      await setHeight(containerHeight);
      // console.log(containerHeight);
    };

    // Call the adjustFontSize function initially and whenever the window is resized
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, []);


  //calculate the workWeekDataChart
  useEffect(() => {
    if (currentUser?.personalWorkWeek) {
      setPersonalWorkWeekData([
        currentUser.personalWorkWeek.working_minutes_monday,
        currentUser.personalWorkWeek.working_minutes_tuesday,
        currentUser.personalWorkWeek.working_minutes_wednesday,
        currentUser.personalWorkWeek.working_minutes_thursday,
        currentUser.personalWorkWeek.working_minutes_friday,
        currentUser.personalWorkWeek.working_minutes_saturday,
        currentUser.personalWorkWeek.working_minutes_sunday,
      ]);
    } 
    // else {
    //   setPersonalWorkWeekData([ //default
    //     //define a class workWeek!
    //     60, 60, 60, 60, 60, 60, 60,
    //   ]);
    // }
  }, [currentUser]);

  const [personalWorkWeekDataChart, setPersonalWorkWeekDataChart] = useState(Array.from(
    { length: 21 },
    (_, i) => personalWorkWeekData[Math.floor(i / 3)])
  );

  useEffect(() => {
    setPersonalWorkWeekDataChart(Array.from(
      { length: 21 },
      (_, i) => personalWorkWeekData[Math.floor(i / 3)])
    )
  }, [personalWorkWeekData])

  /**
   * main Freezer
   */

  useEffect(() => {
    // console.log("verify: ", verifyWorkloadChart, " calibrate: ", calibratingWorkload)
    if (
      verifyWorkloadChart !== undefined &&
      !verifyWorkloadChart &&
      !calibratingWorkload
    ) {
      // console.log("workload freeze process is over");
      setFreezeWorkloadChart(false); //workload freeze process is over
    }
  }, [verifyWorkloadChart, calibratingWorkload]);

  /**
   * calculate the maximum background level for visualization
   * (this does not calculate the Workload Minutes themselves!)
   */
  useEffect(() => {
    // console.log(
    //   workloadMinutes ===undefined
    // );
    if (workloadMinutes && suggestedWorkloadMinutes && personalWorkWeekData) {
      // console.log(workloadMinutes);
      // console.log(suggestedWorkloadMinutes);
      const maximumWorkloadMinutes =
        workloadService.calculateMaximumWorkloadMinutes(
          personalWorkWeekData,
          workloadMinutes,
          suggestedWorkloadMinutes
        );

      setMaxMinutesDay(maximumWorkloadMinutes);
    }
  }, [workloadMinutes, suggestedWorkloadMinutes, personalWorkWeekData]);

  /**
   * Calculate the workload for the class AND individual AND Teacher!
   */
  //unlock calibration
  useEffect(() => {
    const unlock = async () => {
      // console.log("We are allowed to calculate the graphs");
      await setCalibratingWorkload(true);
    };
    // console.log("verify: ", verifyWorkloadChart)
    if (verifyWorkloadChart) {
      unlock();
    }
  }, [verifyWorkloadChart]);

  useEffect(() => {
    // console.log("calibrate: ", calibratingWorkload, " relevant:", relevantUserAssignments == undefined)
    if (
      calibratingWorkload &&
      relevantUserAssignments &&
      relevantClassAssignments
    ) {
      calibrateWorkloadData(); //sets calibratingWorkload to false once process is finished
    }
  }, [calibratingWorkload, relevantUserAssignments, relevantClassAssignments]);

  const calibrateWorkloadData = async () => {
    await setVerifyWorkloadChart(false); // Change the locks
    // console.log(relevantUserAssignments)
    const relevantPersonalWeekAssignments = relevantUserAssignments;

    let relevantClassWeekAssignments;
    if (!isALeader) {
      relevantClassWeekAssignments = relevantPersonalWeekAssignments;
    } else {
      //prepare the relevant classWeekAssignments
      relevantClassWeekAssignments =
        await updateRelevantClassAssignmentsForChart(
          relevantClassAssignments,
          showCourseAssignmentOnly,
          selectedCourse
        );
    }
    // console.log("class assignments: ", relevantClassWeekAssignments);
    /**
     * Get Dictionaries
     */
    // Get dict of relevant assignments by day (0: monday, 6: sunday)

    const {
      relevantPersonalAssignmentsByDay,
      relevantClassAssignmentsByDay,
      relevantExtraDateAssignmentsByDay,
    } = await getRelevantAssignmentsByDay(
      relevantPersonalWeekAssignments,
      relevantClassWeekAssignments,
      referenceDate
    );
    // console.log(relevantPersonalAssignmentsByDay)
    /**
     * calculate WorkloadMinuts
     */
    //personal chart
    const personalWorkloadMinutes = await calculateWorkloadMinutesChart(
      referenceDate,
      relevantPersonalAssignmentsByDay,
      "personal",
      relevantExtraDateAssignmentsByDay
    );
    // console.log("minutes", personalWorkloadMinutes)
    await setWorkloadMinutes(personalWorkloadMinutes);

    //class chart
    const classWorkloadMinutes = await calculateWorkloadMinutesChart(
      referenceDate,
      relevantClassAssignmentsByDay,
      "class"
    );
    // console.log(classWorkloadMinutes);
    await setSuggestedWorkloadMinutes(classWorkloadMinutes);

    /**
     * Calculate how many times the current workload does not fit into the planned time
     */
    await setPersonalNumberOfDoesNotFit(
      await calculateFitsInWeek(
        referenceDate,
        relevantPersonalAssignmentsByDay,
        relevantExtraDateAssignmentsByDay,
        "personal",
        personalWorkWeekData
      )
    );

    await setClassNumberOfDoesNotFit(
      await calculateFitsInWeek(
        referenceDate,
        isAStudent
          ? relevantPersonalAssignmentsByDay
          : relevantClassAssignmentsByDay,
        relevantExtraDateAssignmentsByDay,
        "class",
        personalWorkWeekData
      )
    );

    // await console.log("calibrating is over")

    await setCalibratingWorkload(false);
  };

  return (
    <>
      <BoxHeader
        title={
          isAStudent
            ? "Dein Workload"
            : selectedCourse && showCourseAssignmentOnly
              ? `Workload im Kurs ${selectedCourse?.name ? selectedCourse.name : ""}`
              : selectedCourse
                ? `Workload der Klasse ${selectedCourse?.name ? createTeacherName(selectedCourse.name) : ""}`
                : `Arbeitsbelastung Klasse`
        }
        options={isMobile ? ["personalWeek"] : ["personalWeek", "workloadFlip"]}
        workloadFlip={workloadFlip}
        setWorkloadFlip={setWorkloadFlip}
      />

      <MiddleBox>
        <div
          style={{ height: "100%", overflow: "hidden" }}
          ref={containerRef}
        >
          {/* Adjust the height as needed */}
          <div>
            <WorkloadChartComponent
              height={height}
              maxMinutesDay={maxMinutesDay}
              timeBudget={personalWorkWeekDataChart}
              userWorkload={
                workloadMinutes
              }
              classWorkload={
                suggestedWorkloadMinutes
              }
            />
          </div>
          <div style={{ marginTop: "-1.5em" }}>
            {!calibratingWorkload && <WorkloadFitsInComponent />}
          </div>
        </div>
      </MiddleBox>
    </>
  );
}
export default memo(WorkloadChart);
