import React, { memo } from "react";
import dayjs from "dayjs";
import "dayjs/locale/de"; // Import the locale you want to use
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "moment/locale/de";
import MuiPicker from "../../3_Picker/MuiPicker";
dayjs.locale("de"); // this changes to German and changes the weekday start to monday

function DatumBox({ restricted, dueDate, change, classView, boxType, special, extraDate }) {
  // const [selectedDate, setSelectedDate] = useState(dueDate)
  return (
    <>
      {/* Datum Box */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiPicker
          changeDate={change}
          chosenDate={dueDate}
          restricted={restricted}
          classView={classView}
          boxType={boxType}
          special={special}
          extraDate={extraDate}
        />
      </LocalizationProvider>
    </>
  );
}

export default memo(DatumBox);
