import React, { memo } from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import { useTheme } from "@mui/styles";
import { Paper } from "@mui/material";
import moment from "moment";
import { ChartsReferenceLine } from "@mui/x-charts";
import { useHusibuechContext } from "../../../../8_contexts/husibuechContext";

moment.locale("de");

function WorkloadChartComponent({ maxMinutesDay, userWorkload, classWorkload, timeBudget, height }) {

  const {
    isALeader, referenceDate
  } = useHusibuechContext()
  const theme = useTheme()
  const weeks = ["m", "Montag", "mo", "d", "Dienstag", "di", "mi", "Mittwoch", "mit", "do", "Donnerstag", "don", "f", "Freitag", "fr", "sa", "Samstag", "sam", "so", "Sonntag", "son"]
  const axisValues = [99, 1, 98, 97, 2, 96, 95, 3, 94, 93, 4, 92, 91, 5, 90, 89, 6, 88, 87, 7, 86]


  const WorkloadToolTip = (props) => {
    // console.log("axisData:", props);

    const { axisData, series } = props;
    if (!axisData?.x?.value || typeof axisData.x.value !== "number") {
      return <div>hi</div>; // or handle the case appropriately
    } else {
      return (
        <Paper sx={{ padding: 3, marginTop: "-5em", marginLeft: "2em", marginRight: "2em" }}>
          <div style={{ display: 'flex', color: theme.palette.background.white }}>
            {/* <div>{`Persönliche Belastung: ${axisData.x.index},`}&nbsp;</div> */}
            <div>{`Zeitbelastung am ${weeks[axisData.x.index % 3 === 0 ? axisData.x.index + 1 : axisData.x.index % 3 === 2 ? axisData.x.index - 1 : axisData.x.index]}:`}</div>
          </div>
          <div style={{ display: 'flex', color: theme.palette.background.white, marginBottom: "0.5em" }}>
            {/* {`Montag: ${moment(mondays[axisData.x.index]).format("DD. MMMM")}`} */}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ color: series[2].color }}>{series[2].label}:&nbsp;</div>
            <div style={{ color: series[2].color }}>{`${Math.round(series[2].data[axisData.x.index])} min`}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ color: series[1].color }}>{series[1].label}:&nbsp;</div>
            <div style={{ color: series[1].color }}>{`${Math.round(series[1].data[axisData.x.index])} min`}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ color: series[0].color }}>{series[0].label}:&nbsp;</div>
            <div style={{ color: series[0].color }}>{`${Math.round(series[0].data[axisData.x.index])} min`}</div>
          </div>
        </Paper>
      );
    }
  };

  return (
    <>
      <div
        style={{
          marginTop: "-2.5em", marginLeft: "0.3em", paddingBottom: "1em", overflow: "hidden",
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}>
        <LineChart
          tooltip={{ trigger: "axis", axisContent: WorkloadToolTip }}
          sx={{
            //general for graph:
            // backgroundColor: "green",
            paddingBottom: "-1em",
            overflow: "hidden",
            //rot element:
            '& .MuiLineElement-root': {
              // stroke: theme.palette.primary.main, // Adjust this color as needed
              strokeWidth: 2,
            },
            //style the lines:
            '& .MuiAreaElement-series-time': {
              fill: "url('#workloadChartGradient')",
            },
            // both Line Styles
            "& .MuiChartsAxis-root .MuiChartsAxis-line": {
              stroke: theme.palette.primary.main,
              strokeWidth: 1,
            },
            //change stroke color of both axes
            "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
              stroke: theme.palette.primary.main,
              strokeWidth: 0.5,
            },
            // change all labels fontFamily shown on both xAxis and yAxis
            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
              fontFamily: "Oswald",
            },
            //change left yAxis label styles
            "& .MuiChartsAxis-root .MuiChartsAxis-tickLabel": {
              fill: theme.palette.primary.main,
              // stroke: theme.palette.primary.main,

            },
            //change stroke color of both axes
            "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
              stroke: theme.palette.primary.main,
              strokeWidth: 0.5,
            },
            "& .MuiChartsAxis-root .MuiChartsAxis-tickLabel": {
              fill: theme.palette.primary.main,
              // stroke: theme.palette.primary.main,

            },
            // both Line Styles
            "& .MuiChartsAxis-root .MuiChartsAxis-line": {
              stroke: theme.palette.primary.main,
              strokeWidth: 1,
            },
            "& .MuiChartsAxis-bottom .MuiChartsAxis-label": {
              fill: theme.palette.primary.main,
            },
            "& .MuiChartsAxis-left .MuiChartsAxis-label": {
              fill: theme.palette.primary.main,
            },

            // both Line Styles
            "& .MuiChartsAxis-directionX": {
              // "& .MuiChartsAxis-tickLabel": {
              //   fill: theme.palette.primary.main,
              //   // rotate: "-40deg",
              //   // transform: 'translateX(-2.1em) translateY(-0.7em)', // Adjust the translateY value as needed
              // },
            },

            "& .MuiChartsAxis-left .MuiChartsAxis-label": {
              fill: theme.palette.primary.main,
              transform: 'translateX(-0.5em)',
            },
            '.MuiLineElement-series-time': {
              strokeDasharray: '5 5 2 5',
              opacity: 0.3
            },
            "& .MuiChartsAxis-root .MuiChartsAxis-directionX": {
              // fill: theme.palette.primary.main,
            }
          }}

          xAxis={[{
            // label: 'Woche',
            scaleType: 'point',
            data: axisValues,
            id: 'bottomAxis',
            scaleType: 'point',
            valueFormatter: (number) => `${moment(referenceDate)
              .startOf("week")
              .isoWeekday(number)
              .format("dddd")}`,
            tickInterval: (number) => number < 10,


          }]}

          yAxis={[{
            label: 'Zeitaufwand [in Minuten]',
            tickMaxStep: 60,
            tickMinStep: 60,
            min: 0,
            max: maxMinutesDay ? maxMinutesDay < 180 ? 180 : maxMinutesDay : 180,
          }]}

          series={[
            {
              id: 'time',
              label: isALeader ? 'Ihr Zeitbudget' : 'Dein Zeitbudget',
              data: timeBudget ? timeBudget : Array.from({ length: 21 }, (index) => `${index}`),
              // stack: 'total',
              curve: "linear",
              showMark: false,
              color: theme.palette.levels.green,
              // showMark: ({ index }) => index % 2 === 0,
              area: true,
              valueFormatter: (element) =>
                `${element}min`
            },
            {
              id: 'personal',
              label: isALeader ? 'Ihr persönlicher Aufwand' : 'Dein persönlicher Aufwand',
              data: userWorkload ? userWorkload : Array.from({ length: 21 }, () => 0),
              // stack: 'total',
              curve: "linear",
              showMark: false,
              color: theme.palette.primary.main,
              // showMark: ({ index }) => index % 2 === 0,
              area: false,
              valueFormatter: (element) =>
                `${element}min`
            },
            {
              id: 'class',
              label: 'Aufwand der Klasse',
              data: classWorkload ? classWorkload : Array.from({ length: 21 }, () => 0),
              // stroke: "transparent",
              curve: "linear",
              showMark: false,
              color: theme.palette.secondary.main,
              stack: 'total',
              area: false,
              valueFormatter: (element) =>
                `${element}min`
            },
          ]}
          slotProps={{ legend: { hidden: true } }}
          skipAnimation={false}
          // width={500}
          height={height}
        >
          <defs>
            <linearGradient id="workloadChartGradient" gradientTransform="rotate(90)">
              <stop offset="20%" stopColor={theme.palette.levels.green} stopOpacity="0.1" />
              <stop offset="100%" stopColor={theme.palette.levels.green} stopOpacity="0.5" />
            </linearGradient>
          </defs>
          {moment().isoWeek() === moment(referenceDate).isoWeek() &&
            <ChartsReferenceLine
              x={moment().isoWeekday()}
              label="heute"
              lineStyle={{ stroke: theme.palette.levels.lightGreen, strokeDasharray: '5 5' }}
              labelAlign='start'
              labelStyle={{ fill: theme.palette.levels.lightGreen }}
            />}
        </LineChart>
        {/* } */}
      </div >
    </>
  );
}

export default memo(WorkloadChartComponent);
