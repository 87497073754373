import React, { forwardRef } from "react";

const MainContentGrid = forwardRef(({ children }, ref) => (
  <div
    style={{
      flex: 1,
      display: "grid", // Grid Layout verwenden
      gridTemplateColumns: "repeat(auto-fit, minmax(49%, 1fr))", // Flexibles Layout
      gap: "1rem", // Abstand zwischen den Kacheln
      padding: "1rem", // Padding für den Container
      width: "100%", // Vollständige Breite des Containers
      overflowY: "scroll", // Vertikale Scrollbarkeit aktivieren
      overflowX: "hidden", // Horizontale Scrollbar ausblenden
      scrollbarWidth: "none", // Verhindert das Anzeigen der Scrollbar in Firefox
      WebkitScrollbar: "none", // Verhindert das Anzeigen der Scrollbar in Webkit-basierten Browsern (z.B. Chrome, Safari)
      // backgroundColor: "green", // Zum Testen (optional)

    }}
    ref={ref} // Pass the ref properly to the div element
  >
    {children}
  </div>
));

export default MainContentGrid;
