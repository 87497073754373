import { memo } from "react";
import { Grid, LinearProgress } from "@mui/material";
import CircularStatic from "./CircularProgressWithLabel";

function LoadingDashboardContent() {
  return (
    <>
      <LinearProgress color="primary" />
      <div
        style={{
          display: "flex",         // Use flexbox for layout
          flexDirection: "column", // Stack items vertically (from top to bottom)
          alignItems: "center",    // Center the items horizontally
          justifyContent: "center", // Center the items vertically
          minHeight: "20rem",         // Ensure the container has a fixed height
          gap: "3rem",             // Add gap between the items
        }}
      >
        {/* <Grid container alignItems="center" direction="column">
          <Grid item xs={12} sx={{ marginTop: { xs: "8em" } }}> */}
            <CircularStatic />
          {/* </Grid> */}
        </div>
      </>
      );
}
      export default memo(LoadingDashboardContent);
