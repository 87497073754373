import React, { memo } from "react";
import MyButton from "../../../../4_generalHelpers/2_Buttons/MyButton";
import MessageBox from "./MessageBox";

function HusibuechButton({ change, loading, color, message, job, disabled }) {

    return (
        < div
            style={{
                marginTop: "1em",
                display: "flex", // Set display to "flex"
                justifyContent: "space-between", // Set justifyContent to "space-between"
            }
            }
        >
            <div
                style={{
                    visibility: !change ? "hidden" : "visible",
                    marginRight: "10px", // Adjust the value to add space
                }}
            >
                <MyButton
                    loading={loading}
                    job={job}
                    text={"Speichern"}
                    disabled={disabled}
                    color={color}
                />
            </div>

            {/* Feedback Message */}
            <div>
                {message && <MessageBox message={message} color={color} />}
            </div>
        </div >

    );
}

export default memo(HusibuechButton);
